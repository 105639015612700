import React from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Button from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import { useScroll } from "../../commonComponents/ScrollContext";
import "./style.scss";
import { Helmet } from "react-helmet-async";
const paths = [
  { label: "Home", url: "/" },
  { label: "Work Integrated Programs", url: "/work-integrated-programs/" },
];
const ProgramBanner = () => {
  const { scrollToFooter } = useScroll();
  return (
    <>
      <Helmet>
        <title>
          Work Integrated Learning Programs - Online Degree Apprenticeships
        </title>
        <meta
          property="og:title"
          content="Work Integrated Learning Programs - Online Degree Apprenticeships"
        />
        <meta property="og:site_name" content="Digivarsity" />
        <meta
          property="og:url"
          content="https://www.digivarsity.com/work-integrated-programs/"
        />
        <meta
          property="og:description"
          content="Explore work integrated learning programs (WILP) at Digivarsity. Enroll in our online degree apprenticeships and achieve career success with our dynamic blended learning."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.digivarsity.com/static/media/career1.e4d1f2b9d76502e7cc47.png"
        />
        <meta property="og:image:width" content="566" />
        <meta property="og:image:height" content="380" />

        <meta
          name="description"
          content="Explore work integrated learning programs (WILP) at Digivarsity. Enroll in our online degree apprenticeships and achieve career success with our dynamic blended learning."
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Work Integrated Programs",
                item: "https://www.digivarsity.com/work-integrated-programs/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="program-banner">
        <div className="program-path">
          <BreadCrumbs paths={paths} />
        </div>
        <div className="left-section">
          <div className="title">
            <h1>
              <span>Work</span> Integrated Degrees
            </h1>
            <br />
            <h2>Making you Career-ready</h2>
            <div className="program-motto">
              <KeyboardDoubleArrowDownIcon />
              <Button className={"scrollDown"} onClick={scrollToFooter}>
                Scroll down
              </Button>
              <KeyboardDoubleArrowDownIcon />
            </div>
          </div>
        </div>
      </div>
      <div class="understanding-prog-container">
        <div className="understanding-prog">
          <h2>Understanding Work Integrated Degree Programs</h2>
          <p>
            <span>Work Integrated Degree Programs</span> are the ultimate fusion
            of academic excellence and real-world experience! <br /> <br /> Say
            hello to hands-on learning that prepares you for success in today's
            competitive job market. Imagine diving headfirst into real workplace
            environments while earning your degree - that's the power of Work
            Integrated Degree Programs. These innovative initiatives seamlessly
            blend classroom instruction with practical work experience,
            equipping you with the skills, critical thinking abilities, and
            industry-specific knowledge needed to thrive in your chosen field.{" "}
            <br /> <br /> It's not just education; it's preparation for a
            successful career.
          </p>
        </div>
      </div>
    </>
  );
};
export default ProgramBanner;
