import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ButtonComponent from '../../commonComponents/Button';
import BreadCrumbs from '../../commonComponents/BreadCrumbs';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AllTheBestIcon from "../../assets/congrats-icon-image.png"
import { Helmet } from 'react-helmet-async';

const SummaryPath = () => {
    const username = sessionStorage.getItem('name');
    const isPathPersonalize = sessionStorage.getItem('path-personalize');
    const navigate = useNavigate();

    const paths1 = [
        { label: "Home", url: "/" },
        { label: "Personalise Pathway", url: "/personalize-pathway/" },
        { label: "Summary", url: "/pathway-comparison/summary/" },
    ];
    const paths2 = [
        { label: "Home", url: "/" },
        { label: "Summary", url: "/pathway-comparison/summary/" },
    ];
    const paths = isPathPersonalize==='true'? paths1 : paths2;

  return (
    <>
    <Helmet>
    <link rel="canonical" href={window.location.href}/>
    <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
    </Helmet>
        <div className="summary-path-congrats-page">
            <div className="top-section">
            <ButtonComponent className={"back-button"} onClick={()=>navigate(-1)}> <KeyboardBackspaceIcon /> Back </ButtonComponent>
                <BreadCrumbs paths={paths} color="black" />
            </div>
        </div>
    </>
  )
}

export default SummaryPath