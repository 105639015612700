import appSetting from "../configuration/appsettings";

let apiRootUrl = `${appSetting.ApiServerURL[appSetting.environment]}`;
export const ApiRootUrl = apiRootUrl;
export const REFRESH_TOKEN = `${apiRootUrl}api/Digivarsity/GenerateToken`;
export const AspirationAPI = `${apiRootUrl}api/Digivarsity/GetAspirationList`;
export const IndustryAPI = `${apiRootUrl}api/Digivarsity/GetIndustryList`;
export const QualificationAPI = `${apiRootUrl}api/Digivarsity/GetQualificationList`; 
export const CourseListAPI = `${apiRootUrl}api/Digivarsity/GetCourseList`;
export const UniversityListAPI = `${apiRootUrl}api/Digivarsity/GetUniversityList`;
export const LocationListAPI = `${apiRootUrl}api/Digivarsity/GetLocationList`;   
export const JobAPI = `${apiRootUrl}api/Digivarsity/GetJobList`;
export const GenerateOTPAPI = `${apiRootUrl}api/Digivarsity/GenerateOTP`;
export const ValidateOTPAPI = `${apiRootUrl}api/Digivarsity/ValidateOTP`;
export const getComparedJobListAPI = `${apiRootUrl}api/Digivarsity/GetComparedJobList`;
export const saveJobForComparisonAPI = `${apiRootUrl}api/Digivarsity/SaveJobForComparison`;
export const DeleteJobForComparisonAPI = `${apiRootUrl}api/Digivarsity/DeleteComparedJobList`;
export const AddToLikeListAPI = `${apiRootUrl}api/Digivarsity/AddToLikeList`;
export const InitiateAssessmentAPI = `${apiRootUrl}api/Assessment/InitiateAssessment`;
export const UniversityDetailsAPI = `${apiRootUrl}api/Digivarsity/UniversityDetails`;
export const GetSlotsAvailableAPI = `${apiRootUrl}api/Digivarsity/GetSlotsAvailable`;
export const BookSlotsAPI = `${apiRootUrl}api/Digivarsity/BookSlots`;
export const ConfirmPathwayAPI = `${apiRootUrl}api/Digivarsity/ConfirmPathway`;
export const GetConfirmedPathwayAPI = `${apiRootUrl}api/Digivarsity/GetConfirmedPathway`;
export const FaqAPI = `${apiRootUrl}api/Digivarsity/FAQsList`;
export const CoureDetailsAPI = `${apiRootUrl}api/Digivarsity/CourseDetails`;
export const OurEcoSystemAPI = `${apiRootUrl}api/Digivarsity/OurEcoSystem`;
export const UniversityWiseCourseAPI = `${apiRootUrl}api/Digivarsity/UniversityWiseCourse`;
export const PartnerWithUsAPI = `${apiRootUrl}api/Digivarsity/PartnerWithUs`;
export const ContactUsAPI = `${apiRootUrl}api/Digivarsity/ContactUs`;
export const CorporateCuratedAPI = `${apiRootUrl}api/Digivarsity/CorporateDetails`;
export const AssetsAPI = `${apiRootUrl}api/Resource/GetMedia/media`;
export const AptAPI = `${apiRootUrl}api/Assessment/AssessentResult`;
export const AptStatusAPI = `${apiRootUrl}api/Assessment/UserResultStatus`;

