import React, { createContext, useContext, useState, useEffect } from 'react';
import callAPI from "../utils/apiAction";
import { OurEcoSystemAPI, UniversityDetailsAPI, UniversityWiseCourseAPI } from "../utils/apiUrl";

const IdsContext = createContext();

export const IdsProvider = ({ children }) => {
  const [universityId, setUniversityId] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [corporateId, setCorporateId] = useState(null);
  const [universityMap, setUniversityMap] = useState({});
  const OurEcoSystemURL = OurEcoSystemAPI;
  const [corporateMap, setCorporateMap] = useState({});
  const UniversityWiseCourseURL = UniversityWiseCourseAPI;
  const [courseMap, setCourseMap] = useState({});


//UNIVERSITY CONTEXT  
  useEffect(() => {
    // Fetch the university data from the API
    const fetchUniversityData = async () => {
      try {
        const queryParams = { unvId: universityId };
        const { data } = await callAPI(
          UniversityDetailsAPI,
          "POST",
          queryParams,
          {
            Authorization: "Bearer YourAccessToken",
          }
        );
        if (data && data.length) {
          const map = {};
          data.forEach(university => {
            map[university.univId] = university.universityName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
            .replace(/[\s-]+/g, '-')
          });
          setUniversityMap(map); // Update the state
        }
       
      } catch (error) {
        console.log(error);
      }
    };
    fetchUniversityData();
  }, [universityId]);
  useEffect(() => {
    if (universityMap) {
      console.log("University Map: ",universityMap); // Log universityMap once it's set
    }
  }, [universityMap]); // Log only when universityMap changes
  useEffect(() => {
    // Load corporate ID from local storage if available
    const storedUniversityId = localStorage.getItem('universityId');
    if (storedUniversityId) {
      setUniversityId(storedUniversityId);
    }
  }, [setUniversityId]);
  useEffect(() => {
    // Save corporate ID to local storage whenever it changes
    if (universityId) {
      localStorage.setItem('universityId', universityId);
    }
  }, [universityId]);




// CORPORATE CONTEXT 
  useEffect(() => {
    const queryParams = {
      _courseId: 0
    };
    const fetchEcoData = async () => {
      try {
        const { data } = await callAPI(OurEcoSystemURL, "POST", queryParams, {
          Authorization: "Bearer YourAccessToken",
        });
        console.log(data);
        
        if (data[0].corporatePartner && data[0].corporatePartner.length) {
          const map = {};
          data[0].corporatePartner.forEach(corporate => {
            map[corporate.corporateId] = corporate.corporateName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
            .replace(/[\s-]+/g, '-')
                        
          });
          setCorporateMap(map); // Update the state
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchEcoData();
  }, [corporateId]);
  useEffect(() => {
    if (corporateMap) {
      console.log("Corporate Map: ",corporateMap); // Log universityMap once it's set
    }
  }, [corporateMap]); // Log only when universityMap changes
  useEffect(() => {
    // Load corporate ID from local storage if available
    const storedCorporateId = localStorage.getItem('corporateId');
    if (storedCorporateId) {
      setCorporateId(storedCorporateId);
    }
  }, [setCorporateId]);








//COURSE CONTEXT
useEffect(()=>{
  const fetchCourseData = async () => {
    
      try {
        const { data } = await callAPI(
          UniversityWiseCourseURL,
          "POST",
          {
            univId: universityId,
          },
          {
            Authorization: "Bearer YourAccessToken",
          }
        );
        console.log("Course Id Context: ", data);
        // setUniWiseCourseData(data);
        if (data && data.length) {
          const map = {};
          data.forEach(course => {
            map[course.courseId] = course.courseName.toLowerCase().replace(/[^\w\s-]/g, '')  // Remove all non-word characters except spaces and hyphens
            .replace(/[\s-]+/g, '-')
                          
          });
          console.log(map);
          setCourseMap(map); // Update the state
        }
        // setLoading(false);
      } catch (error) { 
        console.log(error);
        // setLoading(false);
      }
    
   
  };
  fetchCourseData();
},[courseId, universityId]);
useEffect(()=>{
  if(courseMap){
    console.log("Course Map: ",courseMap);
  }
},[courseMap])
  useEffect(() => {
    // Load corporate ID from local storage if available
    const storedCourseId = localStorage.getItem('courseId');
    if (storedCourseId) {
      setCourseId(storedCourseId);
    }
  }, [setCourseId]);
  useEffect(() => {
    // Save corporate ID to local storage whenever it changes
    if (courseId) {
      localStorage.setItem('courseId', courseId);
    }
  }, [courseId]);


  return (
    <IdsContext.Provider value={{ universityId, setUniversityId,universityMap, corporateId, setCorporateId, corporateMap,courseMap, courseId, setCourseId }}>
      {children}
    </IdsContext.Provider>
  );
};

export const useIds = () => useContext(IdsContext);

