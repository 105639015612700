import React from "react";
const lounges = [
  {
    id: "001",
    location: "Dehradun - Uttarakhand",
    address: "Uttaranchal University, Premnagar, Dehradun - 248007",
    contact: "(+91) 716-878-9900",
  },
  {
    id: "002",
    location: "Guwahati - Assam",
    address: (
      <>
        <p>1. The Co Working Space Z, 3rd Floor, Aditya Tower, Opposite Down Town Hospital, GS Road, Guwahati - 781006</p>
        <br></br>
        <p>2. Arya Vidyapeeth College (Autonomous), Gopinath Nagar, Guwahati - 781016</p>
      </>
    ),
    contact: "(+91) 716-878-9900",
  },
  {
    id: "003",
    location: "Guntur - Andhra Pradesh",
    address: "Acharya Nagarjuna University Dr. YSR College of ANU College of Engineering & Technology, ECE and EEE Block, Acharya Nagarjuna University, Nagarjuna Nagar, Guntur - 522510",
    contact: "(+91) 716-878-9900",
  },
  {
    id: "004",
    location: "Visakhapatnam - Andhra Pradesh",
    address: "Andhra University Chinna Waltair Road, Visakhapatnam - 530003",
    contact: "(+91) 716-878-9900",
  },
];

const CareerLoungeCard = ({ id, location, address, contact, className }) => {
  return (
    <>
      <div className={className} id={id}>
        <div className="lounge-content">
          <h2>{location}</h2>
          <div className="lounge-subcontent">
            <h4 className="info">Address - </h4>
            <h4 className="details">{address}</h4>
          </div>
          <div className="lounge-subcontent">
            <h4 className="info">Contact - </h4>
            <h4 className="details">{contact}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

const CareerLounges = () => {
  return (
    <>
      <div className="career-lounges">
        <div className="heading">
          <h1>Career Lounges</h1>
        </div>
        <div className="content">
          {lounges.map((item, index) => {
            return (
              <>
                <CareerLoungeCard
                  id={item.id}
                  location={item.location}
                  address={item.address}
                  contact={item.contact}
                  className={"loungecard"}
                />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CareerLounges;
