import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Emergency from "../../assets/emergency_home.png";
import "./disclaimer.scss";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Disclaimer = () => {
  const location = useLocation();
  const { url, university_name} = location.state || {}; // Destructure url from state, default to an empty object
  console.log(location.state);

  useEffect(() => {
    function getUniversityInitials(universityName) {
      let lowerCaseName = universityName.toLowerCase();
      let words = lowerCaseName.split(/[\s-()]+/);
      let initials = words.map(word => word.charAt(0)).join('');
      return initials + '_ums';
    }


    if (university_name) {
      const initials = getUniversityInitials(university_name);
      console.log(initials); 
    }
  }, [university_name]);
  
  // console.log("Received URL in Disclaimer:", location.state);
  return (
    <div className="discparentdiv">
    <Helmet>
    <link rel="canonical" href={window.location.href}/>

    </Helmet>
      <div className="disclaimer-div">
        <div className="imgdiv">
          <img src={Emergency} alt="Emergency" />
        </div>
        <div className="headdiv">
          <h2>Disclaimer</h2>
        </div>
        <div className="ponediv">
          <p>
            The showcased Work Integrated Degree Programs on Digivarsity are curated based on current available information provided by participating employers and universities.
            <br /><br />
            Digivarsity does not collect any fee as a part of the application process. Any payments made by students as part of admission process are directly collected by the university via the university platform.
          </p>
        </div>
        <Link to={url} target='_blank'>
          <div className="continuediv">
            <button className='primary-btn'>Continue with Application</button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Disclaimer;
