import React , {useState, useEffect}from 'react'
import HorizontalStepper from '../../commonComponents/HorizontalStepper'
import Signin from './Signin'
import Degree from './Degree'
import University from './University'
import Location from './Location'
import Opportunity from './Opportunity'
import FindPathway from './FindPathway'
import { useActiveStep } from '../../utils/ActiveStepContext'
import { useNavigate } from 'react-router-dom'
const PathwayStepper = () => {
  const navigate = useNavigate();
  const isLoggedIn = sessionStorage.getItem('signInCompleted'); 

  const {activeStep, setActiveStep, setSelectedDegree, setSelectedUniversity, setSelectedLocation, setSelectedJob, dataAvailable} = useActiveStep(); 
  const handleNavigate = () =>{
    navigate("/pathway-comparison/");
  }
  useEffect(() => {
    if (activeStep === 5) { 
      setActiveStep(isLoggedIn===true? 1 : 0); 
      setSelectedDegree(null);
      setSelectedUniversity(null);
      setSelectedLocation(null);
      setSelectedJob([]);
    }
  }, [activeStep, isLoggedIn, setActiveStep, setSelectedDegree, setSelectedUniversity, setSelectedLocation, setSelectedJob]);
  

  // useEffect(()=>{
  //     const handleNextAction = () => {
  //       let nextButton;
  //       console.log(activeStep);
  //       switch(activeStep){
  //         case 1: nextButton = document.querySelector('.degree-page'); console.log(nextButton&&nextButton); break;
  //         case 2: nextButton = document.querySelector('.university-page');break;
  //       }
     
  //       }}
  //     };

  //     handleNextAction();
  // },[activeStep])
  // let nextButtonElement;
  const [nextButton, setNextButton]= useState(null);
  // useEffect(() => {
  //   console.log(activeStep);
  //   const handleNextAction = () => {
  //     setTimeout(() => {
  //       let nextButtonElement;
  //       let classnameToAdd;
  //       const classesToRemove = ["degree_next", "university_next", "location_next", "opportunity_next"]; // Array of classes to remove
        
  //       switch (activeStep) {
  //         case 1:
  //           nextButtonElement = document.querySelector('.degree-page');
  //           classnameToAdd = "degree_next";
  //           break;
  //         case 2:
  //           nextButtonElement = document.querySelector('.university-page');
  //           classnameToAdd = "university_next";
  //           break;
  //         case 3:
  //           nextButtonElement = document.querySelector('.location-page');
  //           classnameToAdd = "location_next";
  //           break;
  //         case 4:
  //           nextButtonElement = document.querySelector('.opportunity-page');
  //           classnameToAdd = "opportunity_next";
  //           break;
  //         default:
  //           break;
  //       }
  
  //       if (nextButtonElement) {
  //         console.log("Setting nextButton:", nextButtonElement);
  //         setNextButton(() => {
  //           console.log("Next Button Updated in callback:", nextButtonElement);
  //           return nextButtonElement;
  //         });
  
  //         const siblingElement = nextButtonElement.nextElementSibling;
  //         siblingElement.className = ""; // Clear sibling's className
  
  //         // Remove classes from children[2]
  //         if (siblingElement.children[2] && activeStep !== 1) {
  //           classesToRemove.forEach((cls) => {
  //             siblingElement.children[2].classList.remove(cls);
  //           });
  
  //           siblingElement.children[2].classList.add(classnameToAdd);
  //           console.log("Class added to sibling:", siblingElement);
  //         } else {
  //           classesToRemove.forEach((cls) => {
  //             siblingElement.children[0].classList.remove(cls);
  //           });
  
  //           siblingElement.children[1].classList.add(classnameToAdd);
  //           console.log("Class added to sibling:", siblingElement);
  //         }
  //       }
  //     }, 1000); // Adjust the delay if needed
  //   };
  
  //   handleNextAction();
  // }, [activeStep]);
  
  useEffect(() => {
    console.log(activeStep);
    const handleNextAction = () => {
      setTimeout(() => {
        let nextButtonElement;
        let classnameToAdd;
        const classesToRemove = ["degree_next", "university_next", "location_next", "opportunity_next"]; // Array of classes to remove
        
        switch (activeStep) {
          case 1:
            nextButtonElement = document.querySelector('.degree-page');
            classnameToAdd = "degree_next";
            break;
          case 2:
            nextButtonElement = document.querySelector('.university-page');
            nextButtonElement.nextElementSibling.style.display =" flex";
            classnameToAdd = "university_next";
            break;
          case 3:
            nextButtonElement = document.querySelector('.location-page');
            classnameToAdd = "location_next";
            break;
          case 4:
            nextButtonElement = document.querySelector('.opportunity-page');
            classnameToAdd = "opportunity_next";
            break;
          default:
            break;
        }
  
        if (nextButtonElement) {
          // console.log("Setting nextButton:", nextButtonElement);
          setNextButton(() => {
            // console.log("Next Button Updated in callback:", nextButtonElement);
            return nextButtonElement;
          });
  
          const siblingElement = nextButtonElement.nextElementSibling;
          siblingElement.className = ""; // Clear sibling's className
  
          if(siblingElement.childElementCount>1){// Remove all classes from both children[2] and children[1]
          classesToRemove.forEach((cls) => {
            if (siblingElement.children[2]) {
              siblingElement.children[2].classList.remove(cls);
            }
            if (siblingElement.children[1]) {
              siblingElement.children[1].classList.remove(cls);
            }
          });
  
          // Add the correct class based on the active step
          if (siblingElement.children[2] && activeStep !== 1) {
            siblingElement.children[2].classList.add(classnameToAdd);
            // console.log("Class added to sibling[2]:", siblingElement);
          } else {
            siblingElement.children[1].classList.add(classnameToAdd);
            // console.log("Class added to sibling[1]:", siblingElement);
          }}
        }
      }, 1000); // Adjust the delay if needed
    };
  
    handleNextAction();
  }, [activeStep]);
  
  // useEffect(() => {
  //   console.log(activeStep)
  //   const handleNextAction = () => {
  //     setTimeout(() => {
  //     let nextButtonElement;
  //     let classnameToAdd;
  //     switch (activeStep) {
  //       case 1:
  //         nextButtonElement = document.querySelector('.degree-page');
  //         classnameToAdd = "degree_next";

  //         break;
  //       case 2:
  //         nextButtonElement = document.querySelector('.university-page');
  //         classnameToAdd = "university_next";
  //         break;
  //       case 3:
  //         nextButtonElement = document.querySelector('.location-page');
  //         classnameToAdd = "location_next";
  //         break;
  //       case 4:
  //         nextButtonElement = document.querySelector('.opportunity-page');
  //         classnameToAdd = "opportunity_next";
  //         break;
  //       default:
  //         break;
  //     }
  
  //     if (nextButtonElement) {
  //       console.log("Setting nextButton:", nextButtonElement);
  //       setNextButton(() => {
  //         console.log("Next Button Updated in callback:", nextButtonElement);
  //         return nextButtonElement;
  //       });

  //       //code
  //       const siblingElement = nextButtonElement.nextElementSibling;
  //       siblingElement.className = ""; 
  //       if (siblingElement.children[2] && activeStep!==1) {
          
  //         siblingElement.children[2].classList.add(classnameToAdd);
  //         console.log("Class added to sibling:", siblingElement);
  //       }
  //       else{
  //         siblingElement.children[0].classList.add(classnameToAdd);
  //         console.log("Class added to sibling:", siblingElement);
  //       }
  //     }
  //   }, 1000); // Adjust the delay if needed
  //   };
  
  //   handleNextAction();
  // }, [activeStep]);
  

  return (
    <>
        <HorizontalStepper 
            stepLabels={['Signin', 'Degree', 'University', 'Location', 'Opportunity']}
            stepComponents={[
                <Signin/>,
                <Degree />,
                <University />,
                <Location />,
                <Opportunity />
            ]}
            FinishComponent={() => <FindPathway />}
            showNextButton={activeStep >0 && dataAvailable}
            showBackButton={activeStep>1 && dataAvailable}
            stepperClassName={'stepper-connector'}
            finalButtonContent="Next"
            buttonNextClassName={'next-button'}
            buttonBackClassName={'back-button-stepper'}
            finalButtonAction={handleNavigate}
        />

        {activeStep ===2 && (
          <div className='university-disclaimer'>
            <p><span>*Please note:</span> Enrollment is subject to meeting the eligibility criteria of the university.</p>
          </div>
        )}
    </>
  )
}
export default PathwayStepper
