import React from 'react'
import BreadCrumbs from "../../commonComponents/BreadCrumbs"
import { news } from '../Home/News';
const paths = [
  { label: 'Home', url: '/' },
  { label: 'Digiverse', url: '/digiverse/' },
  { label: 'News', url: `/news/${news[0].route}` },
];
const NewsPath = () => {
  return (
    <div className='news-path'>
      <BreadCrumbs paths={paths} color="black"/>
      <h1 className='heading'>Latest News</h1>
    </div>
  )
}
export default NewsPath