import React from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import "./style.scss";
import { Helmet } from "react-helmet-async";

const paths = [
  { label: "Home", url: "/" },
  { label: "Terms & Conditions", url: "/terms-policy/" },
];

function TermsPolicy() {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Digivarsity</title>
        <meta
          name="Description"
          content="Read Digivarsity's Terms and Conditions to understand your rights and responsibilities. Learn about our policies and usage guidelines."
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Terms Policy",
                item: "https://www.digivarsity.com/terms-policy/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="termsPolicyWrapper">
        <div className="terms-policy-path">
          <BreadCrumbs paths={paths} color={"black"} />
        </div>
        <div className="policy-container">
          <div className="policy-content-container">
            <h1>Terms And Conditions</h1>
            <p>
              Every effort has been made to ensure that the information put
              forward is accurate. Digivarsity will take all reasonable steps to
              provide the educational services described. However, the content
              of individual courses and the curriculum for any given University
              are under constant academic review and may change from time to
              time with some courses being modified, discontinued or replaced.
              In addition, external factors such as industrial action or the
              death or departure of staff may make it impossible or possible
              only at the disproportionate expense, for the Digivarsity to
              provide a previously offered option. In the interest of rational
              deployment of resources, options offered may be subject to a
              minimum level of interest being shown in any given year.
            </p>
            <br />
            <p className="sub-para">
              For all the foregoing reasons, Digivarsity reserves the right not
              to provide any particular course, curriculum or facility, to make
              variations to the content or method of delivery of courses, to
              discontinue courses and to merge or combine courses if such action
              is reasonably considered to be necessary by the Associated
              Universities. If any University mentioned on Digivarsity
              discontinues any courses it will use reasonable endeavours to
              provide a suitable alternative course and will take all reasonable
              steps to minimize any disruption, which might result from such
              changes. The students undertake to observe the Ordinances and
              Resolutions of the individual Universities and to abide by the
              rules and regulations, which the University makes for its students
              from time to time.
            </p>
            <br />

            <p>
              The following terms and conditions, along with all other terms and
              legal notices located on digivarsity.com (collectively, "Terms"),
              govern your use of digivarsity.com (the "Website"). If you do not
              understand and agree to be bound by all Terms, do not use this
              Website. Your use of this Website at any time constitutes a
              binding agreement by you to abide by these Terms.
            </p>
            <p className="registration-heading">Registration</p>
            <ul>
              <p>On registration you agree to:</p>
              <li>
                Registration On registration you agree to: Make your contact
                details available to Digivarsity’s Programs; you may be
                contacted by Digivarsity for education information through
                email, telephone, and SMS.
              </li>
              <li>
                Receive promotional emails/special offers from Digivarsity or
                any of its partner websites
              </li>
              <li>
                Be contacted in accordance with the privacy settings set by you.
              </li>
            </ul>
            <p className="registration-heading">
              Restrictions Regarding Materials
            </p>
            <p>
              All information, documents, software, images, photographs, text,
              services and other similar materials (collectively, "Materials")
              contained on this Website are provided by the Universities
              associated with the website or its third party service providers,
              authors, developers and vendors ("Third Party Providers") and are
              the copyrighted work of the Universities associated with the
              website and/or the Third Party Providers. Except as stated herein,
              none of the Materials may be copied, reproduced, distributed,
              republished, downloaded, displayed, posted or transmitted in any
              form or by any means, including, but not limited to, electronic,
              mechanical, photocopying, recording, or otherwise, without the
              prior express written permission of Digivarsity. No part of the
              Website, including logos, graphics, sounds or images, may be
              reproduced or retransmitted in any way, or by any means, without
              the prior express written permission of Digivarsity. You also may
              not, without Digivarsity's prior express written permission,
              "mirror" any Materials contained on this Website on any other
              server.
              <br />
              Nothing on this Website shall be construed as conferring any
              license under any of the Universities mentioned on Digivarsity or
              any Third Party Provider's intellectual property rights, whether
              by estoppels, implication, or otherwise.
            </p>
            <p>
              You acknowledge sole responsibility for obtaining any such
              licenses.
              <br />
              Permission is granted to display, copy, distribute and download
              the materials belonging to the Universities or any Third Party
              mentioned on this Website provided that:
            </p>
            <p>
              (1) both the copyright notice identified below and this permission
              notice appear in the Materials,
              <br />
            </p>
            <p>
              (2) the use of such Materials is solely for personal,
              non-commercial and informational use and will not be copied or
              posted on any networked computer or broadcast in any media, and
              <br />
            </p>
            <p>
              (3) no modifications of any of the Materials are made. This
              permission terminates automatically without notice if you breach
              any of these terms or conditions. Upon termination, you will
              immediately destroy any downloaded or printed Materials. Any
              unauthorized use of any Materials contained on this Website may
              violate copyright laws, trademark laws, and other related laws and
              may have various legal consequences.
              <br />
            </p>
            <p>
              (4)Materials provided by Third Party Providers have not been
              independently authenticated in whole or in part by Digivarsity.
              www.digivarsity.com does not provide, sell, license, or lease any
              of the Materials other than those specifically identified as being
              provided by the Universities or other parties associated with
              Digivarsity. Digivarsity is committed to respecting others'
              intellectual property rights, and we ask our users to do the same.
              <br />
              ‘Digivarsity’ (hereinafter referred to as "We") may, in its sole
              discretion, terminate the access rights of users who infringe or
              otherwise violate others' intellectual property rights.
              <br />
            </p>

            <p className="registration-heading">Local Laws</p>
            <p>
              If you use this Website from other locations, you are responsible
              for compliance with applicable local laws including but not
              limited to the export and import regulations of other countries.
              Unless otherwise explicitly stated, all marketing or promotional
              materials found on this Website are solely directed to
              individuals, companies or other entities located in India and
              comply with the laws prevailing for the time being in force in
              India. Disputes if any shall be subject to the exclusive
              jurisdiction of Courts at Mumbai.
            </p>
            <p className="registration-heading">General</p>
            <p>
              General This Website could include unintended inaccuracies or
              typographical errors. Digivarsity and the Third Party Providers
              may make improvements and/or changes in the products, services,
              programs, and prices described in this Website at any time without
              notice. Changes are periodically made to the Website. The
              material, information, links, and content presented on and by this
              website is of a general nature only and is not intended to address
              the specific circumstances, requirements, or any other needs of
              any particular individual or entity. This website is sometimes
              linked to external websites over which Digivarsity has no control
              and assume no responsibility, and are in no way acting as a
              publisher of material, information, links, and content contained
              on external linked websites. Links may become invalid, may expire,
              or may become misdirected at any time. Links are provided as a
              convenience and do not necessarily constitute, signify, or
              otherwise imply an endorsement by, or an endorsement for, or a
              relationship with, or connection to ‘Digivarsity’. The statements
              expressed on external linked websites are not those of
              ‘Digivarsity’; and users are advised that ‘Digivarsity’ does not
              maintain editorial control over external linked websites or
              determine the appropriateness regarding the material, information,
              links, and content contained on external linked websites.
              ‘Digivarsity’ has no control over any external website or over any
              external material, information, links, and content linked to
              digivarsity.com
              <br />
              digivarsity.com will have people from all over India/the world
              using this Website. So in order to ensure consistency in the
              interpretation and enforcement of these Terms, the Website, and
              your use of the Website will be governed in all respects by the
              laws of India
              <br />
              These Terms represent the entire understanding relating to the use
              of the Website and prevail over any prior or contemporaneous,
              conflicting, additional, or other communications. ‘Digivarsity’
              can modify these Terms at any time without notice or consent by
              updating this posting In the event any or a portion of the
              provisions of these Terms are held invalid, illegal or otherwise
              unenforceable by a Court, such provision shall be deemed amended
              to be valid, legal and enforceable to the fullest extent permitted
              under applicable law, and the remaining provisions of these Terms
              shall remain in full force and effect. These Terms are binding
              upon you and your heirs, representatives, successors, and assigns.
              The headings of the several articles and subdivisions of these
              Terms are inserted solely for the convenience of reference and
              shall have no further meaning, force or effect. No third party
              shall be a beneficiary of any provision of these Terms, except
              with the express written consent of ‘Digivarsity’
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsPolicy;
