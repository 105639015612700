import React, { useState, useEffect } from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import CallIcon from "../../assets/call.png";
import MailIcon from "../../assets/mail.png";
import ChatIcon from "../../assets/WhatsappSVG.svg";
import "./style.scss";
import messageIcon from "../../assets/message-icon.svg";
import numericIcon from "../../assets/contactnumeral.svg";
import { PartnerWithUsAPI } from "../../utils/apiUrl";
import { ContactUsAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import AlertComponent from "../../commonComponents/AlertComponent";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
const ContactBanner = () => {
  const PartnerWithUsURL = PartnerWithUsAPI;
  const ContactUsURL = ContactUsAPI;
  const [partnerMode, setPartnerMode] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    emailId: "",
    phoneNo: "",
    message: "",
  });
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (partnerMode) {
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [partnerMode]);
  useEffect(() => {
    if (partnerMode) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }, [partnerMode]);
  const validateField = (name, value) => {
    const namePattern = /^[A-Za-z]{1,100}$/;
    const emailPattern = /^.{1,50}$/;
    const companyPattern = /^[A-Za-z]{1,100}$/;
    const phonePattern = /^[0-9]{0,10}$/; // Exactly 10 digits
    const messagePattern = /^.{1,400}$/;
    switch (name) {
      case "firstName":
      case "lastName":
        return namePattern.test(value);
      case "companyName":
        return partnerMode ? companyPattern.test(value) : true;
      case "emailId":
        return emailPattern.test(value);
      case "phoneNo":
        return phonePattern.test(value);
      case "message":
        return messagePattern.test(value);
      default:
        return true;
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (validateField(name, value) || value === "") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `Invalid ${name}`,
      }));
    }
  };
  const [showAlert, setShowAlert] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = Object.keys(formData).reduce((acc, field) => {
      if (field === "phoneNo") {
        if (formData[field].length !== 10) {
          acc[field] = `Invalid ${field}`;
          setShowAlert(true);
        }
      }
      if (!validateField(field, formData[field])) {
        acc[field] = `Invalid ${field}`;
      }
      return acc;
    }, {});
    if (Object.keys(formErrors).length === 0) {
      const submissionData = partnerMode
        ? { ...formData, phoneNo: parseInt(formData.phoneNo, 10) }
        : {
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailId: formData.emailId,
            phoneNo: parseInt(formData.phoneNo, 10),
            message: formData.message,
          };
      const url = partnerMode ? PartnerWithUsAPI : ContactUsAPI;
      try {
        const response = await callAPI(url, "POST", submissionData);
        setSuccess(response.isSuccess);
        if (response.statusCode === 401) {
          throw new Error("Unauthorized access. Token might have expired.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    } else {
      setErrors(formErrors);
      setSuccess(false);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Mdoe: ", partnerMode);
    console.log("success: ", success);
    if (partnerMode && success === true) {
      navigate("/thank-you/", { state: { mode: "partner" } });
    } else if (!partnerMode && success === true) {
      navigate("/thank-you/", { state: { mode: "contact" } });
    }
  }, [success, partnerMode]);

  const handlePartner = () => {
    setPartnerMode(true);
    setFormData({
      firstName: "",
      lastName: "",
      companyName: "",
      emailId: "",
      phoneNo: "",
      message: "",
    });
    setSuccess("");
  };
  const paths = [
    { label: "Home", url: "/" },
    {
      label: "Contact us",
      url: "/contact-us/",
      customOnClick: () => {
        setPartnerMode(false);
        setIsVisible(true);
      },
    },
  ];
  if (partnerMode) {
    paths.splice(2, 0, { label: "Partner with us", url: "" });
  }
  return (
    <>
      <Helmet>
        <title>Contact Digivarsity: We're Here to Help</title>
        <meta
          name="Description"
          content="Reach out to Digivarsity for any queries or support. We're here to assist you with your educational and career needs. Contact us today."
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Contact Us",
                item: "https://www.digivarsity.com/contact-us/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="contact-container">
        <AlertComponent
          open={showAlert}
          onClose={() => setShowAlert(false)}
          message="Invalid phone number! Please enter phone number having 10 digits."
          severity="error"
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <div className="breadcrumbs-div">
          <BreadCrumbs paths={paths} color="white" />
        </div>
        <div className="contact-info">
          {partnerMode ? (
            <h1 className="contact-title" id="partner-h1">
              Partner with Digivarsity
            </h1>
          ) : (
            <h1 className="contact-title">Contact Us</h1>
          )}
          <p className="contact-description">
            You may reach us via phone, email or choose to fill up the form{" "}
            {partnerMode ? (
              <span> to partner with us.</span>
            ) : (
              <span> for any queries.</span>
            )}
          </p>
          <div className="contact-details">
            <p>
              <img src={CallIcon} />
              <span>(+91) 720 467 7888</span>
            </p>
            <p>
              <img src={ChatIcon} />
              <span>(+91) 720 467 7888</span>
            </p>
            <p>
              <img src={MailIcon} />{" "}
              {partnerMode ? (
                <span>Info@digivarsity.com</span>
              ) : (
                <span>partner@digivarsity.com</span>
              )}
            </p>
          </div>
          {!partnerMode && (
            <div className="contact-support">
              <div className="support-box">
                <h3>Customer Support</h3>
                <p>
                  Our support team is available from Monday to Saturday between
                  9.00 am - 7.00 pm to address any concerns or queries you have.
                </p>
              </div>
              <div className="support-box">
                <h3>Career Related Inquiries</h3>
                <p>
                  For career related questions or inquiries please contact us at{" "}
                  <br />
                  (+91) 867 776 8899.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="contact-form">
          <div className="contact-headers">
            {partnerMode ? <h2>Get in Touch</h2> : <h2>Request a call back</h2>}
            {partnerMode && <p>You can reach us anytime</p>}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex-form">
              <input
                type="text"
                placeholder="First name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                placeholder="Last name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            {partnerMode && (
              <div className="form-field">
                <input
                  id="companyName"
                  type="text"
                  placeholder="Your company name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
            <div className="form-field">
              <img src={messageIcon} alt="" />
              <input
                type="email"
                placeholder="Your email"
                name="emailId"
                value={formData.emailId}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-field">
              <span>+91</span>
              <input
                type="text"
                placeholder="Phone number"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-field">
              <textarea
                name="message"
                placeholder="Your message"
                rows={5}
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            {success ? (
              <button className="successBtn">Sent Successfully</button>
            ) : (
              <button type="submit">Send</button>
            )}
          </form>
        </div>
      </div>
      {!partnerMode && isVisible && (
        <div className={`partner-section ${partnerMode ? "hide" : ""}`}>
          <h2>Partner with Digivarsity</h2>
          <div className="partner-buttons">
            <button className="email-button">
              <a
                href={`mailto:info@digivarsity.com`}
                style={{ color: "#fff", textDecoration: "none" }}
              >
                Write to us at info@digivarsity.com{" "}
              </a>
            </button>
            <span>Or</span>
            <button className="contact-button" onClick={handlePartner}>
              Contact us
            </button>
          </div>
          <div className="partner-buttons-mobile">
            <button className="email-button">
              {" "}
              <a
                href={`mailto:info@digivarsity.com`}
                style={{ color: "#fff", textDecoration: "none" }}
              >
                Write to us
              </a>
            </button>
            <button className="contact-button" onClick={handlePartner}>
              Contact us
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactBanner;
