//Footer.jsx
import React, { useEffect } from 'react'
import Logo from "../assets/digavarsity_logo.png"
import Playstore from "../assets/installapp.svg"
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '../assets/facebookIcon.svg';
import InstagramIcon from '../assets/instagramIcon.svg';
import YouTubeIcon from '../assets/youtubeIcon.svg';
import LinkedInIcon from "../assets/linkedinIcon.svg";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Button from "../commonComponents/Button";
import { useScroll } from "../commonComponents/ScrollContext";
import { Link, useLocation } from 'react-router-dom';
import { news } from '../pages/Home/News';
import { reports } from '../pages/Digiverse/RelatedReports';
const Footer = () => {
  const { footerRef } = useScroll();
  const location = useLocation();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'auto' });
  },[footerRef])
  const formatTitleForURL = (title) => {
    return title.toLowerCase().replace(/ /g, '-');
  };
  const handleLinkClick = (event, targetPath) => {
    if (location.pathname === targetPath) {
      scrollToTop();
    }
  };
  return (
    <footer className='footer' ref={footerRef}>
        <div className='footer-top'>
            <div className='left-section'>
                <img src={Logo} alt="logo" className='logo'/>
                <p>Digivarsity, known as 'The University of Work', revolutionizes education by embedding work directly into the learning journey. This approach ensures that students graduate not just with academic qualification but with real WORK experience, making them immediately valuable in the professional sphere.</p>
            </div>
            <div className='right-section'>
                <div className='right-top'>
                    <div className='section1'>
                        <h3>DIGIVARSITY</h3>
                        <ul>
                            <Link to="/about/" onClick={(e) => handleLinkClick(e, "/about/") }><li>About Us</li></Link>
                            <Link to="/work-integrated-programs/"  onClick={(e) => handleLinkClick(e, "/work-integrated-programs/") }><li>Work Integrated Degrees</li></Link>
                            <Link to="/eco-system/"  onClick={(e) => handleLinkClick(e, "/eco-system/") }><li>University Partners</li></Link>
                            <Link to="/eco-system/"  onClick={(e) => handleLinkClick(e, "/eco-system/") }><li>Corporate Partners</li></Link>
                            <Link to="/faq/"  onClick={(e) => handleLinkClick(e, "/faq/") }><li>FAQs</li></Link>
                        </ul>
                    </div>
                    <div className='section1'>
                        <h3>DIGIVERSE</h3>
                        <ul>
                            <Link to={`/news/${news[0].route}`}  onClick={(e) => handleLinkClick(e, `/news/${news[0].route}`) }><li>News</li></Link>
                            <Link to="/events/"  onClick={(e) => handleLinkClick(e, "/events/") }><li>Events</li></Link>
                            <Link to={ `/reports/${reports[0].route}`} onClick={(e) => handleLinkClick(e, `/reports/${reports[0].route}`) }><li>Reports</li></Link>
                        </ul>
                    </div>
                    <div className='section1'>
                        <h3>CONTACT US</h3>
                        <div className='icon-text-inline'>
                            <PhoneIcon />
                            <h5>(+91) 720 467 7888</h5>
                        </div>
                        <div className='icon-text-inline'>
                            <MailOutlineIcon />
                            <h5>info@digivarsity.com</h5>
                        </div>
                    </div>
                </div>
                <div className='right-bottom'>
                    <div className='section1'>
                        <h3>Connect With Us</h3>
                        <ul className='connect-us-icons'>
                            <a href='https://www.facebook.com/digivarsity.the.uni.of.work'  target='_blank'><li><img src={FacebookIcon}/></li></a>
                            <a href='https://www.instagram.com/digivarsity.the.uni.of.work/' target='_blank'><li><img src={InstagramIcon} /></li></a>
                            <a href='https://www.youtube.com/channel/UCJnL4J428DyhA-KzdNvE5Ng' target='_blank'><li><img src={YouTubeIcon} /></li></a>
                            <a href=' https://in.linkedin.com/company/digivarsity-the-university-of-work' target='_blank'><li><img src={LinkedInIcon} /></li></a>
                        </ul>
                    </div>
                    <div className='section1' >
                        <a href='https://play.google.com/store/apps/details?id=com.lurningo.android&pli=1' target='_blank' style={{"textDecoration":"none", "color":"#fff"}}><h3>Install Mobile App</h3>
                        <div className='connect-us'>
                            <img src={Playstore} alt='playstore'/>
                        </div></a>
                    </div>
                    <Button className={'scroll-top'} onClick={scrollToTop} style={{backgroundColor: "white", color: "black" }}>
                        {/* <ArrowUpwardIcon /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" viewBox="0 0 14 25" fill="none">
                            <path d="M14 7.0229L7 0L0 7.0229L1.17658 8.20327L6.16811 3.1954V25H7.832V3.19556L12.8234 8.20327L14 7.0229Z" fill="black"/>
                        </svg>
                    </Button>
                </div>
            </div>
        </div>
        
        <div className='footer-bottom'>
            <div className='content'>
                <hr  className='horizontal-line'></hr>
                <div className='copyright'>
                    <h6>Copyright &copy;2024 Digivarsity | Powered by TeamLease EdTech</h6>
                    <div style={{"display":"flex"}}>
                    <Link to="/privacy-policy/" style={{color: "white"}}><h5>Privacy Policy  • </h5></Link>
                    <Link to="/terms-policy/" style={{color: "white"}}><h5> Terms & conditions</h5></Link>
                    </div>
                    
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer