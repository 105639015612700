import React, {useState} from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ButtonComponent from "../../commonComponents/Button";
import { useActiveStep } from "../../utils/ActiveStepContext";
import { Link, useNavigate } from "react-router-dom";
import AlertComponent from "../../commonComponents/AlertComponent"
import { useScroll } from "../../commonComponents/ScrollContext";
import { useLoginState } from "../../utils/LoginStateContext";
const CustomizePathwayPath = () => {
  const navigate = useNavigate();
  const {activeStep, decrementStep} = useActiveStep();
  const [showAlert, setShowAlert] = useState(true);
  const paths = [
    { label: "Home", url: "/" },
    { label: "Personalize-Pathway", url: "/personalize-pathway/" },
  ];
  const { scrollToChoosePathway } = useScroll();
  const { isLoggedIn, userInfo, login, logout } = useLoginState();
  const handleBack = () => {
    if(activeStep===0){
      navigate("/");
    }
    else if (activeStep===1 && isLoggedIn){
      navigate("/");
      const delayTime = 400;
      const timer = setTimeout(() => {
        scrollToChoosePathway();
      }, delayTime);
      return () => clearTimeout(timer);

    }
    else{
      decrementStep();
    }

  };
  return (
    <div className="customize-pathway">
      <div className="top-section">
        <div className="pathway-breadcrumb-div">
        <ButtonComponent className={"back-button"} onClick={handleBack}> <KeyboardBackspaceIcon /> Back </ButtonComponent>
        <BreadCrumbs paths={paths} color="black" />
        </div>
      </div>
      {activeStep===0 && (
        <div className="motive-container">
          <div className="motive">
            <AccessTimeIcon />
            <p>Find your best career pathway in just 2 minutes!</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomizePathwayPath;
