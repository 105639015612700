import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const ConfirmSlotPath = () => {
  const paths = [
    { label: "Home", url: "/" },
    { label: "Personalise Pathway", url: "/personalize-pathway/" },
    { label: "Summary", url: "/pathway-comparison/summary/" },
    { label: "Confirm Slot", url: "/confirm-slot/" },
  ];
  const navigate = useNavigate();
  return (
    <>
      <div className="find-pathway-path">
        <div className="top-section">
          <ButtonComponent
            className={"back-button"}
            onClick={() => navigate(-1)}
          >
            {" "}
            <KeyboardBackspaceIcon /> Back{" "}
          </ButtonComponent>
          <BreadCrumbs paths={paths} color="black" />
        </div>
      </div>
    </>
  );
};
export default ConfirmSlotPath;
