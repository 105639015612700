import React, {useState,useEffect} from 'react'
import ButtonComponent from '../../commonComponents/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useActiveStep } from "../../utils/ActiveStepContext"
import { useLoginState } from '../../utils/LoginStateContext'
import ModalComponent from '../../commonComponents/ModalComponent'
import Signin from '../CustomizePathway/Signin'

const PathwayPersonalize = () => {
  const { setSelectedJobList } = useActiveStep();
  const { isLoggedIn } = useLoginState();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);
  
  useEffect(() => {
    let personalizeButton = document.querySelector('.pathway-personalize'); // Use a valid selector like .className
    const classnameToAdd = "personalizecareer";
  
    if (personalizeButton) {
      personalizeButton.children[0].classList.add(classnameToAdd); // Add the class to the element's classList
    }
  },[]);

  return (
    <>
        <div className='pathway-personalize'>
            <Link to="/personalize-pathway/"><ButtonComponent onClick={()=>{
              sessionStorage.setItem("path-personalize", true);
              setSelectedJobList([]);
              }} children="Personalize your career journey" className={'pathway-personalize__button'}/></Link>
        </div>

    </>
  )
}

export default PathwayPersonalize