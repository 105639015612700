import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ModalComponent from "../../commonComponents/ModalComponent";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloseIcon from "@mui/icons-material/Close";
import callAPI from "../../utils/apiAction";
import { AssetsAPI } from "../../utils/apiUrl";
export function ExpSpeakVideo({ onClose, video }) {
  return (
    <>
      <div className="video">
        <div className="videodiv">
          <div className="close-icon" onClick={onClose}>
            <CloseIcon color="white" />
          </div>
          <video src={video} controls autoPlay>
            {" "}
          </video>
        </div>
      </div>
    </>
  );
}
const TestimonialCard = ({
  id,
  img_src,
  title,
  name,
  designation,
  video_src,
  tags
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  return (
    <div
      key={id}
      className="testimonial-card swiper-slide"
      style={{ width: "300px" }}
    >
      <div
        className="div_imgbg"
        style={{
          backgroundImage: `url(${img_src})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "270px",
          width: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          position: "relative",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            opacity: "0.5",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "150px",
          }}
        >
          <ModalComponent
            buttonContent={
              <PlayCircleIcon style={{ color: "white", fontSize: "35px" }} />
            }
            style={{ position: "relative" }}
            children={<ExpSpeakVideo onClose={handleClose} video={video_src} />}
            open={isModalOpen}
            setOpen={setIsModalOpen}
            onOpen={handleOpen}
            onClose={handleClose}
          />
        </div>

        <h3
          style={{
            color: "white",
            padding: "10px",
            position: "relative",
            fontSize: "17px",
            fontWeight: "600",
            lineHeight: "24.84px",
            textTransform: "capitalize",
          }}
        >
          <sup>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 310 310"
              fill="#FFF"
              height="10"
              width="10"
            >
              <path d="M79 144.11c-6 0-11.37.28-16.19.8 8.02-32.82 27.27-48.06 55.31-60.35L103.1 50.31C75.18 62.56 56.9 76.59 43.81 95.82c-15.2 22.35-22.6 51.72-22.6 89.81v16.46c0 31.83.11 57.6 57.79 57.6 57.79 0 57.79-25.87 57.79-57.79 0-31.91.37-57.79-57.79-57.79zm152 0c-6 0-11.37.28-16.19.8 8.02-32.82 27.27-48.06 55.31-60.35L255.1 50.31c-27.92 12.25-46.2 26.28-59.29 45.51-15.2 22.35-22.6 51.72-22.6 89.81v16.46c0 31.83.11 57.6 57.79 57.6 57.79 0 57.79-25.87 57.79-57.79 0-31.91.37-57.79-57.79-57.79z"></path>
            </svg>
          </sup>
          {tags[1].tagName}
          <sup>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 310 310"
              fill="#FFF"
              height="10"
              width="10"
            >
              <path d="M231 167.84c6.02 0 11.42-.28 16.25-.81-7.1 29.03-22.95 44.36-45.88 56.04-5.33 2.71-7.63 9.1-5.23 14.57l6.04 13.77c2.59 5.91 9.62 8.44 15.38 5.53 22.1-11.11 37.39-23.92 48.76-40.63C281.58 193.89 289 164.4 289 126.17v-16.52c0-31.95-.11-57.81-58-57.81-58 0-58 25.97-58 58s-.38 58 58 58zm-152 0c6.02 0 11.42-.28 16.25-.81-7.1 29.03-22.95 44.36-45.88 56.04-5.33 2.71-7.63 9.1-5.23 14.57l6.04 13.77c2.59 5.91 9.62 8.44 15.38 5.53 22.1-11.11 37.39-23.92 48.76-40.63C129.58 193.89 137 164.4 137 126.17v-16.52c0-31.95-.11-57.81-58-57.81-58 0-58 25.97-58 58s-.38 58 58 58z"></path>
            </svg>
          </sup>
        </h3>
      </div>
      <div
        className="base-content"
        style={{
          backgroundColor: "#50515B",
          padding: "20px",
          color: "white",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          minHeight: "70px",
        }}
      >
        <h4
          style={{
            fontWeight: "600",
            textTransform: "capitalize",
            lineHeight: "25.2px",
          }}
        >
          {tags[0].tagName}
        </h4>
        <h5 style={{ lineHeight: "19.6px", fontWeight: "400" }}>
          {tags[2].tagName}
        </h5>
      </div>
    </div>
  );
};

const ExpertSpeak = () => {
  const [expertResponse, setExpertResponse] = useState([]); // Initially set to null
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchAssetData = async () => {
      try {
        const { data } = await callAPI(
          AssetsAPI,
          "POST",
          { pageName: "Home" },
          {
            Authorization: "Bearer YourAccessToken",
          }
        );
        setExpertResponse(data);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.log(error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };
    fetchAssetData();
  }, []);
  const [expertData, setExpertData] = useState([]);
  useEffect(() => {
    if (expertResponse && expertResponse.length > 0) {
      const expertData = expertResponse[0].sections[1] ;
      if (
        expertData &&
        expertData.subSections &&
        expertData.subSections.length > 0 &&
        expertData.subSections[0].sectionAssets.length > 0
      ) {
        setExpertData(expertData.subSections[0].sectionAssets);
      }
    }
  }, [expertResponse]);
  return (
    <div className="expert-speak">
      <div className="heading">
        <h2>Experts Speak</h2>
        <div className="icons-inline">
          <div className="button-prev">
            <KeyboardArrowLeftIcon />
          </div>
          <div className="button-next">
            <KeyboardArrowRightIcon />
          </div>
        </div>
      </div>

      <Swiper
      key={expertData.length}
        slidesPerView={"auto"}
        spaceBetween={"20px"}
        speed={2500}
        loop={true}
        navigation={{
          nextEl: ".button-next",
          prevEl: ".button-prev",
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        {expertData && expertData.map((te, index) => {
          return (
            <>
              <SwiperSlide>
                <TestimonialCard
                  id={index}
                  img_src={te.imageUrl}
                  video_src={te.videoUrl}
                  tags={te.tags}
                />
              </SwiperSlide>
            </>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ExpertSpeak;
