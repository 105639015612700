import React from "react";
import ReportImg1 from "../../assets/digiverse-report-1.png";
import ReportImg2 from "../../assets/digiverse-report-2.png";
import ReportImg3 from "../../assets/digiverse-report-3.png";
import ReportLongImg1 from "../../assets/reports-long-2.png";
import ReportLongImg2 from "../../assets/reports-long.png";
import ReportLongImg3 from "../../assets/reports-long-3.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ButtonComponent from "../../commonComponents/Button";
import { Link } from "react-router-dom";
export const reports = [
  {
    id: "001",
    route: "future-of-apprenticeship",
    title: "Future of Apprenticeship in India",
    time: "5",
    date: "3 June 2023",
    synopsis: "The report discusses the importance of degree apprenticeships in India, highlighting how they blend education with industry training to enhance employability. It emphasises the need for practical skills alongside formal education to address skill shortages and mismatches, suggesting that degree apprenticeships can bridge this gap and improve job readiness.",
    image_src: ReportImg3,
    longimage_src: ReportLongImg1,
    pdf_link:"https://www.teamleaseedtech.com/pdf/Report-Future-of-Apprenticeship-in-India.pdf"
  },
  {
    id: "002",
    route: "career-outlook-report-jan-2023-jun-2023",
    title: "Career Outlook Report - HY1 (Jan 2023 - Jun 2023)",
    time: "5",
    date: "3 June 2023",
    synopsis: "The report tracks job market trends for freshers, offering insights to help them navigate employment opportunities. The report notes a slight increase in hiring intent to 62% for freshers and 68% for all jobseekers from January to June 2023. It identifies in-demand roles, essential skills, and top courses to boost employability.",
    image_src: ReportImg2,
    longimage_src: ReportLongImg3,
    pdf_link: "https://www.teamleaseedtech.com/pdf/Career-Outlook-Report-Jan-June-2024-by-TeamLease-EdTech.pdf"

  },
  {
    id: "003",
    route: "career-outlook-report-jul-2023-dec-2023",
    title: "Career Outlook Report - HY2 (Jul 2023 - Dec 2023)",
    time: "5",
    date: "3 June 2023",
    synopsis: "Explore India's evolving job market dynamics in TeamLease EdTech's latest report. Highlighting a 65% hiring intent for freshers and key skills in demand, it offers insights into job roles and top courses enhancing employability. Essential reading for job seekers and educational institutions adapting to industry expectations.",
    image_src: ReportImg1,
    longimage_src: ReportLongImg2,
    pdf_link:"https://www.teamleaseedtech.com/pdf/latest-hiring-trends-career-outlook-report-HY2-2023.pdf"

  },
];
export const ReportCard = ({ id, image_src, time, title, className }) => {
  return (
    <>
      <div className={className} id={id}>
        <img src={image_src} alt="news" className="new-image-size" />
        <div className="report-content">
          <h6>{time} mins read</h6>
          <h5>{title}</h5>
        </div>
      </div>
    </>
  );
};
const RelatedReports = () => {
  const displayedReports = reports.slice(0,3);
  return (
    <div className="related-reports-section">
      <div className="heading">
        <h1>Reports</h1>
        <Link to={"/reports/"+reports[0].route}  style={{color: "black"}}>  
        <div className='icon-text-inline'>
          <h5>View More </h5>
          <ArrowForwardIcon />
        </div>
        </Link>
      </div>
      <div className="content">
      {displayedReports.map((item, index) => {
        return (
          <div>
            <Link to={"/reports/"+item.route}  style={{color: "white"}}> 
            <ReportCard
              id={item.id}
              image_src={item.image_src}
              time={item.time}
              title={item.title}
              className={'reportcard'}
            />
            </Link>
          </div>
        );
      })}
      </div>
      <div className='mobile-view-more-section'>
        <Link to={"/reports/"+reports[0].route} style={{ color: "white" }}>
          <ButtonComponent className='mobile-view-more-button' children="View More Reports" />
        </Link>
      </div>
    </div>
  );
};
export default RelatedReports;
