import React, { useState, useEffect } from "react";
import programskills from "../../assets/program-skills.png";
import ButtonComponent from "../../commonComponents/Button";
import { InitiateAssessmentAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import ModalComponent from "../../commonComponents/ModalComponent";
import Signin from "../CustomizePathway/Signin";
import { useLoginState } from "../../utils/LoginStateContext";
import { AptitudeTestComponent } from "../Home/AptTest";
import { AptStatusAPI } from "../../utils/apiUrl";
import { useNavigate, Link } from "react-router-dom";
function ProgramSkills() {
  const { isLoggedIn, userInfo, login, logout } = useLoginState();
  const navigate = useNavigate();
  const InitiateAssessmentURL = InitiateAssessmentAPI;
  const [aptiData, setAptiData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const queryParams = {
    mobileNo: sessionStorage.getItem("mobileNo"),
  };
  const fetchAptiData = async () => {
    try {
      const { data } = await callAPI(
        InitiateAssessmentURL,
        "POST",
        queryParams,
        {
          Authorization: "Bearer YourAccessToken",
        }
      );
      setAptiData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const _mobileNo = sessionStorage.getItem("mobileNo");
  const [testStatus, setTestStatus]=useState("");
  const AptStatusURL = AptStatusAPI;
  const fetchData = async () => {
    try {
      const [Response] = await Promise.all([
        callAPI(AptStatusURL, "POST", {
          _mobileNo: _mobileNo,
        }),
      ]);
      const { data } = Response;
      console.log(data[0].status);
      if (data && data.length > 0 && data[0].status) {
        setTestStatus(data[0].status);
      }
      
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(()=>{
    if(testStatus && testStatus === "Completed"){
      navigate("/aptitude/");
    }
  },[testStatus]);
 

  return (
    <>
      <div className="program-skills">
        <div className="left-section">
          <h2>Skill Up: Practical Experience While You Study!</h2>
          <ul>
            <li>
              Gain practical, hands-on experience in your field while studying.
            </li>
            <li>
              Develop essential industry-specific skills and competencies.
            </li>
            <li>Enhance your skills and job readiness upon graduation.</li>
            <li>
              Build a professional network and connections within your industry.
            </li>
            <li>
              Earn a degree while simultaneously gaining valuable work
              experience.
            </li>
          </ul>
        </div>
        <div className="right-section">
          <img src={programskills} alt="program skills" />
        </div>
      </div>
      <div className="aptitude-section">
        <div className="aptitude-format">
          <h2>Join Digivarsity & Grow into a Highly Sought-After Talent</h2>
          {isLoggedIn === true ? (
           <div style={{ fontWeight: '600', textAlign: 'center' }} className="apti-button">
            <Link to="/aptitude/give-test/" onClick={handleClose}  >
                      <p style={{ fontWeight: '600', color:'black', padding:'5px 0', textAlign: 'center' }} > 
                        Take the Aptitude Test
                      </p>
                    </Link>
                    </div>
          ) : (
            <ModalComponent
              buttonContent={
                <ButtonComponent
                  children="Take the Aptitude Test"
                  className={"apti-button"}
                  testStatus={testStatus}
                />
              }
              style={{ backgroundColour: "white" }}
              children={
                <Signin
                  sourceComponent={"aptitude-test"}
                  onClose={handleClose}
                />
              }
              open={isModalOpen}
              setOpen={setIsModalOpen}
              onOpen={handleOpen}
              onClose={handleClose}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ProgramSkills;
