import React from 'react'
import BreadCrumbs from "../../commonComponents/BreadCrumbs"

const paths = [
  { label: 'Home', url: '/' },
  { label: 'Digiverse', url: '/digiverse/' },
  { label: 'Reports', url: '/reports/future-of-apprenticeship/' },
];

const ReportsPath = () => {
  return (
    <div className='reports-path'>
      <BreadCrumbs paths={paths} color="black"/>
      <h1>Reports</h1>
    </div>
  )
}

export default ReportsPath