import React, { useState, useEffect } from "react";
import "./stylehome.scss";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import ButtonComponent from "../../commonComponents/Button";
import banner1 from "../../assets/aptbanner1.png";
import banner2 from "../../assets/aptbanner2.png";
import banner3 from "../../assets/aptbanner3.png";
import Footer from "../../components/FooterLayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InitiateAssessmentAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import { AptStatusAPI } from "../../utils/apiUrl";
import ModalComponent from "../../commonComponents/ModalComponent";
import { AptitudeTestComponent } from "../Home/AptTest";
const AptitudeHome = () => {
  const AptStatusURL = AptStatusAPI;
  const InitiateAssessmentURL = InitiateAssessmentAPI;
  const _mobileNo = sessionStorage.getItem("mobileNo");
  const navigate = useNavigate();
  const queryParams = {
    mobileNo: sessionStorage.getItem("mobileNo"),
  };
  const [aptiData, setAptiData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => setIsModalOpen(false);
  // const handleCloseMenu = () => {
  //   onClose();
  // };

  const paths = [
    { label: "Home", url: "/" },
    { label: "Aptitude Test", url: "/aptitude/give-test/" },
  ];
  const fetchAptiData = async () => {
    try {
      const { data } = await callAPI(
        InitiateAssessmentURL,
        "POST",
        queryParams,
        {
          Authorization: "Bearer YourAccessToken",
        }
      );
      setAptiData(data);
    } catch (error) {
      console.log(error);
    }
  };
  
  const [testStatus, setTestStatus] = useState("");
  const fetchData = async () => {
    try {
      const [Response] = await Promise.all([
        callAPI(AptStatusURL, "POST", {
          _mobileNo: _mobileNo,
        }),
      ]);
      const { data } = Response;
      // console.log(data[0].status);
      if (data && data.length > 0 && data[0].status) {
        setTestStatus(data[0].status);
        if (testStatus && testStatus === "Completed") {
          navigate("/aptitude/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(()=>{
    fetchData();
    fetchAptiData();
  },[])
  useEffect(() => {
    if (testStatus && testStatus === "Completed") {
      navigate("/aptitude/");
    }
  }, [testStatus]);
 
  const location = useLocation();
  const { testURL, status } = location.state || {};
  console.log("TestURL: ", testURL);
  console.log("Status: ", status);

  return (
    <div>
      <div className="apt-home-parent">
        <div className="apt-banner">
          <div className="flex-it">
            <div className="left-section">
              <div className="breadcrumbs-div">
                <BreadCrumbs paths={paths} />
              </div>
              <div className="title">
                <h1>Aptitude Test</h1>
                <p>
                  Discover your ideal career with this comprehensive assessment
                </p>
              </div>
            </div>

            <div className="svgs svg-large">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="656"
                height="500"
                viewBox="0 0 656 500"
                fill="none"
              >
                <g filter="url(#filter0_f_2877_47719)">
                  <path
                    d="M265.061 -26.3534C265.061 -26.3534 215.34 87.4658 235.357 185.386C262.294 317.161 397.919 356.442 421.022 469.456C444.124 582.471 370.976 691.478 370.976 691.478"
                    stroke="#0049D7"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_2877_47719"
                    x="0.608398"
                    y="-238.35"
                    width="654.926"
                    height="1146.53"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="100"
                      result="effect1_foregroundBlur_2877_47719"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="592"
                height="500"
                viewBox="0 0 592 500"
                fill="none"
              >
                <g filter="url(#filter0_f_2877_47720)">
                  <path
                    d="M252.431 -176.286C252.431 -176.286 212.021 -16.9422 241.197 125.782C280.46 317.851 422.092 386.564 455.766 551.289C489.44 716.014 424.742 866.328 424.742 866.328"
                    stroke="#FA1F3C"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_2877_47720"
                    x="0.615234"
                    y="-383.648"
                    width="694.863"
                    height="1461.82"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="100"
                      result="effect1_foregroundBlur_2877_47720"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="480"
                height="500"
                viewBox="0 0 480 500"
                fill="none"
              >
                <g filter="url(#filter0_f_2877_47721)">
                  <path
                    d="M258.24 -144.231C258.24 -144.231 212.861 -9.19531 237.146 109.606C269.828 269.482 408.248 322.481 436.278 459.596C464.307 596.711 395.101 724.969 395.101 724.969"
                    stroke="#06C07D"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_2877_47721"
                    x="-0.00195312"
                    y="-353.774"
                    width="672.963"
                    height="1292.97"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="100"
                      result="effect1_foregroundBlur_2877_47721"
                    />
                  </filter>
                </defs>
              </svg>
            </div>

            <div className="svgs svg-small">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="346"
                height="901"
                viewBox="0 0 346 400"
                fill="none"
              >
                <g opacity="0.6" filter="url(#filter0_f_3049_33410)">
                  <path
                    d="M187.878 152.267C187.878 152.267 162.416 210.554 172.667 260.699C186.461 328.18 255.914 348.296 267.745 406.17C279.576 464.045 242.117 519.867 242.117 519.867"
                    stroke="#0049D7"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3049_33410"
                    x="0.235352"
                    y="0.270508"
                    width="439.821"
                    height="676.298"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="70"
                      result="effect1_foregroundBlur_3049_33410"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="304"
                height="900"
                viewBox="0 0 304 601"
                fill="none"
              >
                <g opacity="0.6" filter="url(#filter0_f_3049_33411)">
                  <path
                    d="M181.403 103.486C181.403 103.486 160.709 185.086 175.65 258.175C195.757 356.533 268.286 391.721 285.53 476.076C302.775 560.431 269.643 637.407 269.643 637.407"
                    stroke="#FA1F3C"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3049_33411"
                    x="0.231445"
                    y="-43.877"
                    width="460.272"
                    height="833.128"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="70"
                      result="effect1_foregroundBlur_3049_33411"
                    />
                  </filter>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="247"
                height="901"
                viewBox="0 0 247 601"
                fill="none"
              >
                <g opacity="0.6" filter="url(#filter0_f_3049_33412)">
                  <path
                    d="M184.732 119.903C184.732 119.903 161.494 189.054 173.93 249.892C190.667 331.764 261.551 358.905 275.905 429.121C290.259 499.337 254.818 565.018 254.818 565.018"
                    stroke="#06C07D"
                    stroke-width="60"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3049_33412"
                    x="0.269531"
                    y="-29.6416"
                    width="449.058"
                    height="748.89"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="70"
                      result="effect1_foregroundBlur_3049_33412"
                    />
                  </filter>
                </defs>
              </svg>
            </div>

            <div className="test-box">
              <h1>Take the Aptitude Test</h1>
              <p>
                Our expert career advisors can help you to understand the
                report.
              </p>
              <ModalComponent
              buttonClassName={"gradient-button"}
              buttonContent={<h2 style={{ textTransform: "capitalize", padding: "0" }}>Let's Go</h2>}
              children={
                    <AptitudeTestComponent
                      testURL={aptiData}
                      status={testStatus!=="" && testStatus}
                      onClose={handleClose}
                    />
                  }
                  open={isModalOpen}
                  setOpen={setIsModalOpen}
                  onOpen={() => {
                    handleOpen();
                    fetchData();
                  }}
                  onClose={handleClose}
             />
            </div>
          </div>
        </div>
        <div className="apt-content">
          <div className="apt-content-heading">
            Discover Your True Potential with Our Aptitude Test
          </div>
          <div className="apt-content-boxes">
            <div className="apt-content-box">
              <div className="apt-text">
                <h2>What is an Aptitude Test?</h2>
                <p>An aptitude test is a scientifically designed assessment that helps you uncover your inherent strengths, skills, and areas of interest. It evaluates your problem-solving abilities, logical reasoning, and proficiency in various subjects, giving you a comprehensive understanding of your capabilities. This isn't just a test—it's your roadmap to a successful and fulfilling career.</p>
              </div>
              <div className="apt-img">
                <img src={banner1} alt="" />
              </div>
            </div>
            <div className="apt-content-box column">
              <div className="apt-img">
                <img src={banner2} alt="" />
              </div>
              <div className="apt-text">
                <h2>Why Should You Take This Test?</h2>
                <p>Choosing the right career path can be overwhelming, especially with so many options available. Our aptitude test empowers you to make informed decisions by identifying the fields where you naturally excel. Whether you are uncertain about your future or looking to validate your choices, this test will provide clarity and confidence in your next steps. Discover where you truly shine and unlock opportunities that align with your unique skill set.</p>
              </div>
            </div>
            <div className="apt-content-box">
              <div className="apt-text">
                <h2>What to Expect from the Report?</h2>
                <p>After completing the test, you will receive a detailed report that breaks down your strengths and areas for improvement. This report will highlight potential career paths suited to your abilities, offering insights into professions where you can thrive. You will also receive personalized recommendations, giving you a clear direction for your academic and career journey.</p>
              </div>
              <div className="apt-img">
                <img src={banner3} alt="" />
              </div>
            </div>
            <ModalComponent
              buttonClassName={"gradient-button"}
              buttonContent={<h2 style={{ textTransform: "capitalize", padding: "0" }}>Let's Go</h2>}
              children={
                    <AptitudeTestComponent
                      testURL={aptiData}
                      status={testStatus!=="" && testStatus}
                      onClose={handleClose}
                    />
                  }
                  open={isModalOpen}
                  setOpen={setIsModalOpen}
                  onOpen={() => {
                    handleOpen();
                    fetchData();
                  }}
                  onClose={handleClose}
             />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AptitudeHome;
