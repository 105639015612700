import React from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import { Helmet } from "react-helmet-async";

const paths = [
  { label: "Home", url: "/" },
  { label: "Digiverse", url: "/digiverse/" },
  { label: "Events", url: "/events/" },
];

const EventPath = () => {
  return (
    <>
      <Helmet>
        <title>
          Online Webinars for Students - Education & Career Counseling
        </title>
        <meta
          name="Description"
          content="Join our online free webinars for students and boost your career with expert-led sessions. Discover upcoming webinars, gain insightful actionable and professional development."
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Digiverse",
                item: "https://www.digivarsity.com/digiverse/",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: "Events",
                item: "https://www.digivarsity.com/events/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="event-path">
        <BreadCrumbs paths={paths} color="black" />
        <h1>Events</h1>
      </div>
    </>
  );
};

export default EventPath;
