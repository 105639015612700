import React from "react";
import CareerOneImg from "../../assets/career1.png";
import CareerTwoImg from "../../assets/career2.png";
import WIPRightSVG from "../../assets/WIPRightSVG.svg";
function CareerProgram() {
  return (
    <>
      <div className="career-section">
      <img className="imageSVG" src={WIPRightSVG} alt="" />
        <h2>Ready to supercharge your career?</h2><br/>
        <p>Say hello to Work!</p>
        <div className="left-section-program">
          <div className="left-section-details">
            <p>
              <span className="picture-this-span">Picture this:</span> You're not just learning in a classroom, but you're
              getting hands-on experience in the real world. That's what
              apprenticeship is all about – it's the ultimate blend of learning
              and doing.
              <br />
              <br />
              So, <span>what exactly is apprenticeship?</span> Think of it as your passport
              to success, your ticket to becoming a master of your craft.
              Apprenticeship is a structured training program where you work
              alongside experienced professionals in your chosen field, soaking
              up their knowledge like a sponge.
            </p>
          </div>

          <img src={CareerOneImg} alt="CareerOneImg" />
        </div>
        <div className="right-section-program">
          <img src={CareerTwoImg} alt="CareerTwoImg" />
          <div className="right-sectioon-details">
            <p>
              But wait, there's more! <span>Apprenticeship</span> isn't just about clocking
              in hours – it's about honing your skills, mastering your trade,
              and becoming the best version of yourself.
              <br />
              <br />
              And the best part? You're not just learning for learning's sake –
              you're earning as you learn!
              <br />
              <br />
              Dive in, embrace the journey, and watch as your dreams become
              reality. The world is waiting – are you ready to seize your
              moment?
            </p>
          </div>
        </div>
      </div>
      <div className="study-section">
        <h2>Why Study With Us?</h2>
        <div className="study-details">
        <div className="study-one">
            <h4>Extensive Internship Network</h4>
            <p>We have established partnerships with a wide range of companies across various industries, offering students access to diverse internship opportunities that align with their career goals and interests.</p>
        </div>
        <div className="study-one">
            <h4>Personalized Support</h4>
            <p>Digivarsity provides personalized support to students throughout their academic journey, offering mentorship, career counselling, and professional development resources to help them succeed both academically and professionally.</p>
        </div>
        <div className="study-one">
            <h4>Seamless Integration</h4>
            <p>Digivarsity offers a seamless integration of degree programs with practical work experience, providing students with a unique opportunity to apply theoretical knowledge in real-world settings.</p>
        </div>
        <div className="study-one">
            <h4>Enhanced Skills</h4>
            <p>By combining academic learning with hands-on work experience, we significantly enhance the skills of graduates, equipping them with the practical skills, industry connections, and confidence needed to excel in their careers.</p>
        </div>
        </div>
        
      </div>
    </>
  );
}

export default CareerProgram;
