import React from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import { Helmet } from "react-helmet-async";
const PrivacyPolicyComponent = () => {
  const paths = [
    { label: "Home", url: "/" },
    { label: "Privacy Policy", url: "/privacy-policy/" },
  ];
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Digivarsity</title>
        <meta
          name="Description"
          content="Review Digivarsity's Privacy Policy to understand how we protect your personal information. Learn about our data collection and usage practices."
        ></meta><link rel="canonical" href={window.location.href}/>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Privacy Policy",
                item: "https://www.digivarsity.com/privacy-policy/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="termsPolicyWrapper">
        <div className="terms-policy-path">
          <BreadCrumbs paths={paths} color={"black"} />
        </div>
        <div className="policy-container">
          <div className="policy-content-container">
            <h1>Privacy Policy</h1>
            <p>
              Thank you for visiting the Digivarsity website and reviewing our
              privacy policy.{" "}
              <span>
                By using our services or by otherwise giving us your
                information, you agree to the terms of this privacy policy.
              </span>
            </p>
            <br />
            <p>
              The information we learn from candidates/students/customers helps
              us to personalize and continuously improve your experience.
              <br />
              Here are the types of information we gather:
              <br />
              <ul>
                <li>
                  Information You Give Us: We receive and store any information
                  you enter on our Web site or give us in any other way. You can
                  choose not to provide certain information, but then you might
                  not be able to take advantage of many of our features. We use
                  the information that you provide for such purposes as
                  responses to your requests, customizing future services for
                  you, improving our products and communicating with you.
                </li>
                <li>
                  Automatic Information: We receive and store certain types of
                  information whenever you interact with us.
                </li>
              </ul>
            </p>
            <br />

            <p>Does www.digivarsity.com share the information it receives?</p>
            <br />
            <p>
              Information about our candidates/students/customers is an
              important part of our business, and we are not in the business of
              selling it to others. We share candidates/students/customers
              information only as described below and with our partners.
            </p>
            <br />

            <p>
              How secure is the information about you? We reveal only the last
              four digits of your credit card numbers when confirming an order.
              Of course, we transmit the entire credit card number to the
              appropriate credit card company during order processing. It is
              important for you to protect against unauthorized access to your
              password and to your computer. Be sure to sign off when finished
              using a shared computer. What choices do you have?
            </p>
            <br />

            <p>
              As discussed above, you can always choose not to provide
              information, even though it might be needed to make a purchase.
            </p>
            <br />

            <p>
              Are children allowed to use www.digivarsity.com?
              www.digivarsity.com does not sell products for purchase by
              children. We sell children's products for purchase by adults. If
              you are under 18, you may use www.digivarsity.com only with the
              involvement of a parent or guardian.
            </p>

            <h5 className="registration-heading">
              Why did you receive a communication from us?
            </h5>
            <p>
              Our communication is permission based. If you received a
              mailing/call from us, our records indicate that (a) you have
              expressly shared this address for the purpose of receiving
              information in the future ("opt-in"), or (b) you have registered
              or purchased or otherwise have an existing relationship with us.
              We respect your time and attention by controlling the frequency of
              our mailings.
            </p>

            <h5 className="registration-heading">
              How we protect your privacy?
            </h5>
            <p>
              We use appropriate security measures to protect against the loss,
              misuse and alteration of data used by our system. Conditions of
              Use, Notices, and RevisionsIf you choose to visit
              www.digivarsity.com, your visit and any dispute over privacy is
              subject to this Notice, including limitations on damages,
              resolution of disputes, and application of the law of India. If
              you have any concern about privacy at www.digivarsity.com, please
              contact us with a thorough description, and we will try to resolve
              it. Our business changes constantly and our Privacy Notice will
              change also. We may e-mail periodic reminders of our notices and
              conditions, but you should check our Web site frequently to see
              recent changes. Unless stated otherwise, our current Privacy
              Notice applies to all information that we have about you and your
              account. We stand behind the promises we make, however, and will
              never materially change our policies and practices to make them
              less protective of customer information collected in the past
              without the consent of affected customers.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyComponent;
