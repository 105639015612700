import React from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Button from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import { useScroll } from "../../commonComponents/ScrollContext";
import EcoBanner from "../../assets/ecosystemBanner.png";
import ButtonComponent from "../../commonComponents/Button";
import { Helmet } from "react-helmet-async";
const paths = [
  { label: "Home", url: "/" },
  { label: "Our Ecosystem", url: "/eco-system/" },
];
const EcosystemClickableCard = ({ title, onClick }) => {
  return (
    <ButtonComponent onClick={onClick} className="ecoPatners-button">
      <div className="ecoPatners">
        {" "}
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <mask
            id="mask0_2877_47316"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect
              x="24"
              width="24"
              height="24"
              transform="rotate(90 24 0)"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_2877_47316)">
            <path
              d="M6.35582 6.2942L7.40002 5.25L17.25 15.0904L17.25 6.14422L18.75 6.14422L18.75 17.6442L7.25005 17.6442L7.25005 16.1442L16.1962 16.1442L6.35582 6.2942Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
    </ButtonComponent>
  );
};
const EcoSystemBanner = () => {
  const {
    scrollToPublicPrivatePartners,
    scrollToUniversityPartners,
    scrollToCorporatePartners,
  } = useScroll();
  return (
    <>
      <Helmet><link rel="canonical" href={window.location.href}/>
      <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Eco System",
                item: "https://www.digivarsity.com/eco-system/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="eco-banner">
        <div className="ecosystem-path">
          <BreadCrumbs paths={paths} />
        </div>
        <div className="eco-left-section">
          <div className="eco-title">
            <div className="title-left-section">
              <h1>Our Ecosystem</h1>
              <EcosystemClickableCard
                title="Public Private Partnerships"
                onClick={scrollToPublicPrivatePartners}
              />
              <EcosystemClickableCard
                title="Corporate Partnerships"
                onClick={scrollToCorporatePartners}
              />
              <EcosystemClickableCard
                title="University Partnerships"
                onClick={scrollToUniversityPartners}
              />
            </div>
            <div>
              <img
                src={EcoBanner}
                alt="EcoBanner"
                className="eco-right-section"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EcoSystemBanner;
