import React, { useState, useEffect } from "react";
import { FaqAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
const MinusIcon = () => (
  <div className="svg-list">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M31.5031 16C31.5031 24.5604 24.5629 31.5 16.0015 31.5C7.44023 31.5 0.5 24.5604 0.5 16C0.5 7.43963 7.44023 0.5 16.0015 0.5C24.5629 0.5 31.5031 7.43963 31.5031 16Z"
        fill="white"
        stroke="#9D9D9D"
      />
      <mask
        id="mask0_9060_17735"
        style={{ "mask-type": "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="25"
        height="25"
      >
        <rect
          x="4.00391"
          y="28.0039"
          width="24"
          height="24"
          transform="rotate(-90 4.00391 28.0039)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_9060_17735)">
        <path
          d="M16.0034 14.0581L11.9304 18.1314C11.7919 18.2697 11.6178 18.3405 11.4082 18.3439C11.1987 18.347 11.0214 18.2762 10.8764 18.1314C10.7316 17.9864 10.6592 17.8107 10.6592 17.6044C10.6592 17.398 10.7316 17.2224 10.8764 17.0774L15.3707 12.5831C15.4643 12.4896 15.5631 12.4236 15.6669 12.3851C15.7708 12.3466 15.8829 12.3274 16.0034 12.3274C16.1239 12.3274 16.2361 12.3466 16.3399 12.3851C16.4438 12.4236 16.5425 12.4896 16.6362 12.5831L21.1304 17.0774C21.2688 17.2159 21.3396 17.39 21.3429 17.5996C21.3461 17.8091 21.2753 17.9864 21.1304 18.1314C20.9854 18.2762 20.8098 18.3486 20.6034 18.3486C20.3971 18.3486 20.2214 18.2762 20.0764 18.1314L16.0034 14.0581Z"
          fill="#9D9D9D"
        />
      </g>
    </svg>
  </div>
);
const SmallMinusIcon = () => {
  <div className="svg-list">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M31.5031 16C31.5031 24.5604 24.5629 31.5 16.0015 31.5C7.44023 31.5 0.5 24.5604 0.5 16C0.5 7.43963 7.44023 0.5 16.0015 0.5C24.5629 0.5 31.5031 7.43963 31.5031 16Z"
        fill="white"
        stroke="#9D9D9D"
      />
      <mask
        id="mask0_9060_17735"
        style={{ "mask-type": "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="25"
        height="25"
      >
        <rect
          x="4.00391"
          y="28.0039"
          width="24"
          height="24"
          transform="rotate(-90 4.00391 28.0039)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_9060_17735)">
        <path
          d="M16.0034 14.0581L11.9304 18.1314C11.7919 18.2697 11.6178 18.3405 11.4082 18.3439C11.1987 18.347 11.0214 18.2762 10.8764 18.1314C10.7316 17.9864 10.6592 17.8107 10.6592 17.6044C10.6592 17.398 10.7316 17.2224 10.8764 17.0774L15.3707 12.5831C15.4643 12.4896 15.5631 12.4236 15.6669 12.3851C15.7708 12.3466 15.8829 12.3274 16.0034 12.3274C16.1239 12.3274 16.2361 12.3466 16.3399 12.3851C16.4438 12.4236 16.5425 12.4896 16.6362 12.5831L21.1304 17.0774C21.2688 17.2159 21.3396 17.39 21.3429 17.5996C21.3461 17.8091 21.2753 17.9864 21.1304 18.1314C20.9854 18.2762 20.8098 18.3486 20.6034 18.3486C20.3971 18.3486 20.2214 18.2762 20.0764 18.1314L16.0034 14.0581Z"
          fill="#9D9D9D"
        />
      </g>
    </svg>
  </div>;
};
const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M31.4982 15.998C31.4982 24.5585 24.5586 31.498 15.9981 31.498C7.43767 31.498 0.498047 24.5585 0.498047 15.998C0.498047 7.43764 7.43767 0.498047 15.9981 0.498047C24.5586 0.498047 31.4982 7.43764 31.4982 15.998Z"
      fill="white"
      stroke="black"
    />
    <mask
      id="mask0_9060_17743"
      style={{ "mask-type": "alpha" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="24"
      height="24"
    >
      <rect
        x="28"
        y="4"
        width="24"
        height="24"
        transform="rotate(90 28 4)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_9060_17743)">
      <path
        d="M16.0005 17.9458L20.0735 13.8725C20.212 13.7342 20.3861 13.6634 20.5957 13.66C20.8052 13.6569 20.9825 13.7277 21.1275 13.8725C21.2723 14.0175 21.3447 14.1932 21.3447 14.3995C21.3447 14.6059 21.2723 14.7815 21.1275 14.9265L16.6332 19.4208C16.5396 19.5143 16.4408 19.5803 16.337 19.6188C16.2331 19.6573 16.121 19.6765 16.0005 19.6765C15.88 19.6765 15.7678 19.6573 15.664 19.6188C15.5601 19.5803 15.4614 19.5143 15.3677 19.4208L10.8735 14.9265C10.7351 14.788 10.6643 14.6139 10.661 14.4043C10.6578 14.1948 10.7286 14.0175 10.8735 13.8725C11.0185 13.7277 11.1941 13.6553 11.4005 13.6553C11.6068 13.6553 11.7825 13.7277 11.9275 13.8725L16.0005 17.9458Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
);
const SmallPlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M31.4982 15.998C31.4982 24.5585 24.5586 31.498 15.9981 31.498C7.43767 31.498 0.498047 24.5585 0.498047 15.998C0.498047 7.43764 7.43767 0.498047 15.9981 0.498047C24.5586 0.498047 31.4982 7.43764 31.4982 15.998Z"
      fill="white"
      stroke="black"
    />
    <mask
      id="mask0_9060_17743"
      style={{ "mask-type": "alpha" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="24"
      height="24"
    >
      <rect
        x="28"
        y="4"
        width="24"
        height="24"
        transform="rotate(90 28 4)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_9060_17743)">
      <path
        d="M16.0005 17.9458L20.0735 13.8725C20.212 13.7342 20.3861 13.6634 20.5957 13.66C20.8052 13.6569 20.9825 13.7277 21.1275 13.8725C21.2723 14.0175 21.3447 14.1932 21.3447 14.3995C21.3447 14.6059 21.2723 14.7815 21.1275 14.9265L16.6332 19.4208C16.5396 19.5143 16.4408 19.5803 16.337 19.6188C16.2331 19.6573 16.121 19.6765 16.0005 19.6765C15.88 19.6765 15.7678 19.6573 15.664 19.6188C15.5601 19.5803 15.4614 19.5143 15.3677 19.4208L10.8735 14.9265C10.7351 14.788 10.6643 14.6139 10.661 14.4043C10.6578 14.1948 10.7286 14.0175 10.8735 13.8725C11.0185 13.7277 11.1941 13.6553 11.4005 13.6553C11.6068 13.6553 11.7825 13.7277 11.9275 13.8725L16.0005 17.9458Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
);

const FaqList = () => {
  const FaqURL = FaqAPI;
  const [faqCategory, setFaqCategory] = useState([]);
  const [faqQuestions, setFaqQuestions] = useState([]);
  const [FaqData, setFaqData] = useState([]);
  const [activeTab, setActiveTab] = useState("About Digivarsity");
  const [activeIndex, setActiveIndex] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = screenWidth <= 768;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setActiveIndex(null);
  };

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [FaqResponse] = await Promise.all([
          callAPI(FaqURL, "POST", { CourseId: "0" }),
        ]);
        const { data: FaqData } = FaqResponse;
        setFaqData(FaqData);
        setFaqCategory(FaqData[0].faqCategory);
        setFaqQuestions(FaqData[0].faqQuestions);
      
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const renderQuestions = () => {
    const selectedCategory = faqCategory.find(
      (category) => category.categoryDescription === activeTab
    );
    if (!selectedCategory) return null;

    const questionsForCategory = faqQuestions.filter(
      (question) => question.categoryId === selectedCategory.categoryid
    );

    return questionsForCategory.map((question, index) => (
      <div className="accordion-item" key={index}>
        <div className="accordion-header" onClick={() => handleToggle(index)}>
          <span>{question.questions}</span>
          <span>
            {activeIndex === index ? (
              isMobile ? (
                <SmallMinusIcon />
              ) : (
                <MinusIcon />
              )
            ) : isMobile ? (
              <SmallPlusIcon />
            ) : (
              <PlusIcon />
            )}
          </span>
        </div>
        {activeIndex === index && (
          <div className="accordion-content">
            {question.answers.includes("|") ? (
              question.answers
                .split("|")
                .filter((bulletPoint) => bulletPoint.trim() !== "")
                .map((bulletPoint, index) => (
                  <div key={index} className="flex">
                    <div
                      className={
                        bulletPoint.trim().toLowerCase().startsWith("you")
                          ? ""
                          : "flex"
                      }
                    >
                      {bulletPoint
                        .trim()
                        .toLowerCase()
                        .startsWith("you") ? null : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <circle cx="8" cy="8" r="3" fill="black" />
                        </svg>
                      )}
                    </div>
                    {bulletPoint.trim()}
                  </div>
                ))
            ) : (
              <div className="flex">{question.answers}</div>
            )}
          </div>
        )}
      </div>
    ));
  };
  const [activeAccordion, setActiveAccordion] = useState(null);
  const handleAccordionClick = (question) => {
    setActiveAccordion(question === activeAccordion ? null : question);
  };
  const [activeMobileTab, setActiveMobileTab] = useState(1);
  const handleMobileTabClick = (categoryId) => {
    setActiveMobileTab(categoryId === activeMobileTab ? null : categoryId);
    setActiveAccordion(null); // Reset accordion when switching tabs
  };

  return (
    <div>
      <div className="faqlist-parent">
        <div className="faqlist-parentheader">
          <div className="faqlist-header">
            <h2>FAQS</h2>
            <p>
              Explore our comprehensive FAQs for in-depth answers to common
              queries and clarifications
            </p>
          </div>
        </div>

        {/* DESKTOP  */}
        <div className="faqlist-section">
          <div className="tab-list">
            {faqCategory.map((category) => (
              <div
                key={category.categoryid}
                className={`tab ${
                  activeTab === category.categoryDescription ? "active" : ""
                }`}
                onClick={() => handleTabClick(category.categoryDescription)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="24"
                  viewBox="0 0 9 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_8383_12175)">
                    <path
                      d="M8.37634 13.1115C8.51367 13.0178 8.61659 12.9057 8.68509 12.775C8.75375 12.6442 8.78809 12.5018 8.78809 12.348C8.78809 12.1942 8.75375 12.0518 8.68509 11.921C8.61659 11.7903 8.51367 11.6782 8.37634 11.5845L1.38033 7.13075C1.30483 7.08708 1.2255 7.05442 1.14234 7.03275C1.05934 7.01092 0.980085 7 0.904585 7C0.662752 7 0.451501 7.08458 0.270834 7.25375C0.0900002 7.42308 -0.000414848 7.63975 -0.000414848 7.90375V16.7922C-0.000414848 17.0562 0.090168 17.2729 0.271335 17.4422C0.452501 17.6114 0.663918 17.696 0.905585 17.696C0.981085 17.696 1.06025 17.6851 1.14309 17.6632C1.22592 17.6416 1.305 17.6089 1.38033 17.5652L8.37634 13.1115Z"
                      fill="black"
                    />
                    <path
                      d="M8.37634 13.1115C8.51367 13.0178 8.61659 12.9057 8.68509 12.775C8.75375 12.6442 8.78809 12.5018 8.78809 12.348C8.78809 12.1942 8.75375 12.0518 8.68509 11.921C8.61659 11.7903 8.51367 11.6782 8.37634 11.5845L1.38033 7.13075C1.30483 7.08708 1.2255 7.05442 1.14234 7.03275C1.05934 7.01092 0.980085 7 0.904585 7C0.662752 7 0.451501 7.08458 0.270834 7.25375C0.0900002 7.42308 -0.000414848 7.63975 -0.000414848 7.90375V16.7922C-0.000414848 17.0562 0.090168 17.2729 0.271335 17.4422C0.452501 17.6114 0.663918 17.696 0.905585 17.696C0.981085 17.696 1.06025 17.6851 1.14309 17.6632C1.22592 17.6416 1.305 17.6089 1.38033 17.5652L8.37634 13.1115Z"
                      fill="url(#paint0_linear_8383_12175)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_8383_12175"
                      x1="10.5"
                      y1="12.348"
                      x2="-3.5"
                      y2="12.348"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#A9416F" />
                      <stop offset="0.535" stop-color="#7240B2" />
                      <stop offset="1" stop-color="#2D4CB0" />
                    </linearGradient>
                    <clipPath id="clip0_8383_12175">
                      <rect width="9" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>{" "}
                <h3>{category.categoryDescription}</h3>
              </div>
            ))}
          </div>
          <div className="tab-content">{renderQuestions()}</div>
        </div>

        {/* MOBILE  */}
        <div className="faqlist-section-mobile">
          <div className="tab-list">
            {faqCategory.map((category) => (
              <div key={category.categoryid}>
                <div
                  className={`tab ${
                    activeMobileTab === category.categoryid ? "active" : ""
                  }`}
                  onClick={() => handleMobileTabClick(category.categoryid)}
                >
                  {activeMobileTab === category.categoryid ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="24"
                      viewBox="0 0 11 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_8448_8583)">
                        <path
                          d="M4.7148 15.6252C4.81113 15.7502 4.92648 15.8439 5.06086 15.9062C5.19542 15.9687 5.34179 16 5.5 16C5.65821 16 5.80458 15.9687 5.93914 15.9062C6.07352 15.8439 6.18887 15.7502 6.2852 15.6252L10.8655 9.25687C10.9104 9.18814 10.944 9.11593 10.9663 9.04022C10.9888 8.96467 11 8.89253 11 8.8238C11 8.60367 10.913 8.41137 10.739 8.24691C10.5649 8.0823 10.3421 8 10.0706 8L0.929436 8C0.657933 8 0.435109 8.08246 0.260962 8.24737C0.0869877 8.41228 -4.29615e-07 8.60473 -4.32239e-07 8.82471C-4.33058e-07 8.89344 0.0112276 8.9655 0.0336815 9.04091C0.055964 9.11631 0.0895586 9.18829 0.134466 9.25687L4.7148 15.6252Z"
                          fill="black"
                        />
                        <path
                          d="M4.7148 15.6252C4.81113 15.7502 4.92648 15.8439 5.06086 15.9062C5.19542 15.9687 5.34179 16 5.5 16C5.65821 16 5.80458 15.9687 5.93914 15.9062C6.07352 15.8439 6.18887 15.7502 6.2852 15.6252L10.8655 9.25687C10.9104 9.18814 10.944 9.11593 10.9663 9.04022C10.9888 8.96467 11 8.89253 11 8.8238C11 8.60367 10.913 8.41137 10.739 8.24691C10.5649 8.0823 10.3421 8 10.0706 8L0.929436 8C0.657933 8 0.435109 8.08246 0.260962 8.24737C0.0869877 8.41228 -4.29615e-07 8.60473 -4.32239e-07 8.82471C-4.33058e-07 8.89344 0.0112276 8.9655 0.0336815 9.04091C0.055964 9.11631 0.0895586 9.18829 0.134466 9.25687L4.7148 15.6252Z"
                          fill="url(#paint0_linear_8448_8583)"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_8448_8583"
                          x1="5.49998"
                          y1="17.5583"
                          x2="5.49998"
                          y2="4.8144"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#A9416F" />
                          <stop offset="0.535" stop-color="#7240B2" />
                          <stop offset="1" stop-color="#2D4CB0" />
                        </linearGradient>
                        <clipPath id="clip0_8448_8583">
                          <rect width="11" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="24"
                      viewBox="0 0 9 24"
                      fill="none"
                    >
                      <path
                        d="M7.62519 12.2852C7.7502 12.1889 7.84389 12.0735 7.90624 11.9391C7.96875 11.8046 8 11.6582 8 11.5C8 11.3418 7.96875 11.1954 7.90624 11.0609C7.84389 10.9265 7.7502 10.8111 7.62519 10.7148L1.25687 6.13447C1.18814 6.08956 1.11593 6.05596 1.04022 6.03368C0.96467 6.01123 0.89253 6 0.823804 6C0.603668 6 0.41137 6.08699 0.246913 6.26096C0.0823036 6.43511 5.75182e-08 6.65793 8.12538e-08 6.92944L8.80397e-07 16.0706C9.04132e-07 16.3421 0.0824566 16.5649 0.247369 16.739C0.412282 16.913 0.60473 17 0.824715 17C0.893441 17 0.965505 16.9888 1.04091 16.9663C1.11631 16.944 1.1883 16.9104 1.25687 16.8655L7.62519 12.2852Z"
                        fill="#555555"
                      />
                    </svg>
                  )}
                  {category.categoryDescription}
                </div>
                {activeMobileTab === category.categoryid && (
                  <div className="tab-content">
                    {faqQuestions
                      .filter(
                        (question) =>
                          question.categoryId === category.categoryid
                      )
                      .sort((a, b) => a.orderBy - b.orderBy)
                      .map((question) => (
                        <div className="accordion-item" key={question.orderBy}>
                          <div
                            className="accordion-header"
                            onClick={() =>
                              handleAccordionClick(question.questions)
                            }
                          >
                            <span>{question.questions}</span>
                            <span>
                              {activeAccordion === question.questions ? (
                                <SmallMinusIcon />
                              ) : (
                                <SmallPlusIcon />
                              )}
                            </span>
                          </div>

                          {activeAccordion === question.questions && (
                            <div className="accordion-content">
                              {question.answers.includes("|") ? (
                                question.answers
                                  .split("|")
                                  .filter(
                                    (bulletPoint) => bulletPoint.trim() !== ""
                                  )
                                  .map((bulletPoint, index) => (
                                    <div key={index} className="flex">
                                      <div
                                        className={
                                          bulletPoint
                                            .trim()
                                            .toLowerCase()
                                            .startsWith("you")
                                            ? ""
                                            : "PlusIcon"
                                        }
                                      >
                                        {bulletPoint
                                          .trim()
                                          .toLowerCase()
                                          .startsWith("you") ? null : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                          >
                                            <circle
                                              cx="8"
                                              cy="8"
                                              r="3"
                                              fill="black"
                                            />
                                          </svg>
                                        )}
                                      </div>
                                      {bulletPoint.trim()}
                                    </div>
                                  ))
                              ) : (
                                <div>{question.answers.trim()}</div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqList;
