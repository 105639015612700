import React, { useState, useEffect } from 'react'
import Logo from "../assets/digavarsity_logo.png"
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import Menu from '../pages/Home/Menu';
import Button from "../commonComponents/Button"
import Searchbox from './Searchbox';
import LanguageSelect from './LanguageSelect';
import PopoverComponent from '../commonComponents/Popover';
import AfterSigninProfile from "./AfterSigninProfile";
import Signin from '../pages/CustomizePathway/Signin';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation, Link, Navigate, useNavigate } from 'react-router-dom';
import { useLoginState } from '../utils/LoginStateContext';

const HeaderLayout = ({headerType}) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname); 
  const [hasScrolled, setHasScrolled] = useState(false);
  const { isLoggedIn, userInfo, login, logout } = useLoginState();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(()=>{
    const checkScrollTop = () => {
      if (window.scrollY > 500) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };
    // Add scroll event listener after component mounts
  window.addEventListener('scroll', checkScrollTop);
  return () => window.removeEventListener('scroll', checkScrollTop);
  }, []);

  const headerStyle = (currentPath === '/aptitude/' ||currentPath==='/aptitude/give-test/' || currentPath === '/faq/' ||currentPath === '/about/' || currentPath === '/work-integrated-programs/' || currentPath === '/eco-system/' || currentPath === '/digiverse/')
  ? hasScrolled ? 'header-other' : 'header-transparent' 
  : currentPath === '/contact-us/'
    ? 'header-dark'
    : headerType === 'header-home'
      ? 'header-other'
      : currentPath !== '/'
        ? 'header-other'
        : hasScrolled
          ? 'header-scrolled'
          : 'header-home';


  const [displaySearchBox, setDisplaySearchBox] = useState(false);
  const [displaySearchIcon, setDisplaySearchIcon] = useState(true);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [anchorElSignin, setAnchorElSignin] = useState(null);
  const username = sessionStorage.getItem('name');



  const handleDisplaySearch = () => {
    if (displaySearchBox === false) {
      setDisplaySearchBox(true);
      setDisplaySearchIcon(false);
    }
    else {
      setDisplaySearchBox(false);
      setDisplaySearchIcon(true);
    }
  }
  const handleClose = () => {
    setDisplaySearchBox(false);
    setDisplaySearchIcon(true);
  }

  const closePopover = (setter) => {
    setter(null);
  }

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleClickSignin = (event) => {
    setAnchorElSignin(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleHome = () => {
    navigate('/');
    window.scrollTo(0, 0);  }

  return (
    <>
      <div className={`header ${headerStyle}`}>
        <div className='header-left'>
   
          {(currentPath!=='/' || headerStyle==='header-scrolled') && (
            <Link to="/" onClick={handleHome}>
            <img src={Logo} alt='logo' className='logo' />
            </Link>
          )}
        </div>

       

        <div className='header-right'>

      
          <PopoverComponent
            anchorEl={anchorElMenu}
            handleClick={handleClickMenu}
            handleClose={()=>closePopover(setAnchorElMenu)}
            buttonContent={<MenuIcon className='menu-icon' />} 
            popoverContent={<Menu onClose={()=>closePopover(setAnchorElMenu)} />} 
            buttonClassName={'menu-icon'} 
            popoverClassName={'menu-popover'} 
          />


        

          {isLoggedIn === false? (
            <PopoverComponent 
            anchorEl={anchorElSignin}
            handleClick={handleClickSignin}
            handleClose={()=>closePopover(setAnchorElSignin)}
            buttonContent="Sign In" 
            popoverContent={<Signin sourceComponent={'header'} onClose={()=>closePopover(setAnchorElSignin)}/>} 
            buttonClassName={'signin-button'} 
            popoverClassName={'sigin-corner-popover'}
          />
          ):(
            <PopoverComponent 
            anchorEl={anchorElSignin}
            handleClick={handleClickSignin}
            handleClose={()=>closePopover(setAnchorElSignin)}
            buttonContent={<> <PersonIcon /> Hi {username} </>} 
            popoverContent={<AfterSigninProfile onClose={()=>closePopover(setAnchorElSignin)}/>} 
            buttonClassName={'signin-button-with-name'} 
          />
          )}

        </div>

      </div>
    </>

  )
}

export default HeaderLayout
