import React from 'react'
import BreadCrumbs from "../../commonComponents/BreadCrumbs"
import {Helmet} from "react-helmet-async";
const paths = [
  { label: 'Home', url: '/' },
  { label: 'Digiverse', url: '/digiverse/' },
  { label: 'Reports', url: '/reports/future-of-apprenticeship/' },
];

const ReportsPath = () => {
  return (
    <div className='reports-path'>
    <Helmet><link rel="canonical" href={window.location.href}/>
    <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
    </Helmet>
      <BreadCrumbs paths={paths} color="black"/>
      <h1>Reports</h1>
    </div>
  )
}

export default ReportsPath