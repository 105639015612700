//AboutBanner.jsx
import React from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Button from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import { useScroll } from "../../commonComponents/ScrollContext";
import { Helmet } from "react-helmet-async";
const paths = [
  { label: "Home", url: "/" },
  { label: "About us", url: "/about/" },
];
const AboutBanner = () => {
  const { scrollToFooter } = useScroll();
  return (
    <>
      <Helmet>
        <title>About Digivarsity: Empowering Your Education Journey</title>
        <meta
          name="Description"
          content="Learn about Digivarsity's mission to provide quality online education and career opportunities. Discover our vision and commitment to student success."
        ></meta>
        <link rel="canonical" href={window.location.href}/>

        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "About Us",
                item: "https://www.digivarsity.com/about/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="about-banner">
        <div className="about-path">
          <BreadCrumbs paths={paths} />
        </div>
        <div className="left-section">
          <div className="title">
            <h1>
              Welcome to <span className="digiversity-span">Digivarsity</span>
              <br /> Where <span> Dreams </span> Meet{" "}
              <span> Opportunities, </span>And Education
              <br />
              Transforms Career Goals Into Reality
            </h1>
            <div className="about-motto">
              <KeyboardDoubleArrowDownIcon />
              <Button className={"scrollDown"} onClick={scrollToFooter}>
                Scroll down
              </Button>
              <KeyboardDoubleArrowDownIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutBanner;
