import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoginState } from "../../utils/LoginStateContext";
import { InitiateAssessmentAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import ModalComponent from "../../commonComponents/ModalComponent";
import ButtonComponent from "../../commonComponents/Button";
import { AptitudeTestComponent } from "../Home/AptTest";
import Signin from "../CustomizePathway/Signin";
import {AptStatusAPI} from "../../utils/apiUrl";

const CareerBanner = () => {

  const AptStatusURL = AptStatusAPI;

  const { isLoggedIn } = useLoginState();
  const InitiateAssessmentURL = InitiateAssessmentAPI;
  const location = useLocation();
  const navigate = useNavigate();
  const [aptiData, setAptiData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);
  const queryParams = {
    mobileNo: sessionStorage.getItem("mobileNo"),
  };
  const fetchAptiData = async () => {
    try {
      const { data } = await callAPI(
        InitiateAssessmentURL,
        "POST",
        queryParams,
        {
          Authorization: "Bearer YourAccessToken",
        }
      );
      setAptiData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const _mobileNo = sessionStorage.getItem("mobileNo");
  const [testStatus, setTestStatus]=useState("");
  const fetchData = async () => {
    try {
      const [Response] = await Promise.all([
        callAPI(AptStatusURL, "POST", {
          _mobileNo: _mobileNo,
        }),
      ]);
      const { data } = Response;
      console.log(data[0].status);
      if (data && data.length > 0 && data[0].status) {
        setTestStatus(data[0].status);
      }
      
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(()=>{
    if(testStatus && testStatus === "Completed"){
      navigate("/aptitude/");
    }
  },[testStatus]);
 
  return (
    <div className="apti-test">
      <h1>Not Sure About Your Career Path?</h1>
      {isLoggedIn === true ? (
        <>
          <div className="career-banner-container">
            <Link to="/confirm-slot">
              <ButtonComponent
                children={"Connect with Advisor"}
                className={"advisor-button"}
              />
            </Link>
            <Link to="/aptitude/give-test/" onClick={handleClose}  className="apti-button" style={{
    padding: `16px`,
    fontSize: `18px`,
    color: 'black',
    fontWeight: '700',
    textAlign: 'center'
}}>
                      <p style={{ fontWeight: '600',}}>
                        Take the Aptitude Test
                      </p>
                    </Link>
          </div>
        </>
      ) : (
        <>
          <div className="career-banner-container">
            <ModalComponent
              buttonContent={"Connect with Advisor"}
              buttonClassName={"advisor-button"}
              style={{ backgroundColour: "white" }}
              children={<Signin sourceComponent={"aptitude-test"} onClose={handleClose}/>}
              open={isModalOpen}
              setOpen={setIsModalOpen}
              onOpen={handleOpen}
              onClose={handleClose}
            />

            <ModalComponent
              buttonContent={"Take the Aptitude Test"}
              buttonClassName={"apti-button"}
              style={{ backgroundColour: "white" }}
              children={<Signin sourceComponent={"aptitude-test"} onClose={handleClose} />}
              open={isModalOpen}
              setOpen={setIsModalOpen}
              onOpen={handleOpen}
              onClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CareerBanner;
