//AboutBanner.jsx
import React from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import { useScroll } from "../../commonComponents/ScrollContext";
import { Helmet } from "react-helmet-async";
const paths = [
  { label: "Home", url: "/" },
  { label: "Digiverse", url: "/digiverse/" },
];

const DigiverseBanner = () => {
  const { scrollToFooter } = useScroll();
  return (
    <>
      <Helmet>
        <title>
          Digiverse: Your Hub for News, Events, and Reports | Digivarsity
        </title>
        <meta
          name="Description"
          content="Explore Digiverse for insightful reports, the latest news, blogs, and upcoming events. Stay informed and inspired with Digivarsity's resource hub."
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Digiverse",
                item: "https://www.digivarsity.com/digiverse/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="digiverse-banner">
        <div className="digiverse-path">
          <BreadCrumbs paths={paths} />
        </div>
        <div className="left-section">
          <div className="title">
            <h1>Digiverse </h1>
            <div className="content">
              <p>
                Welcome to Digiverse, your go-to resource hub tailored for your
                academic and career journey. Dive into a curated collection of
                insightful reports, the latest news articles, and upcoming
                events designed to enlighten and inspire. Explore Digiverse -
                where every click shapes your tomorrow.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigiverseBanner;
