//Pathway.jsx
import React, { useEffect, useState } from "react";
import Button from "../../commonComponents/Button";
import AutocompleteTextField from "../../commonComponents/AutocompleteTextField";
import {
  AspirationAPI,
  IndustryAPI,
  QualificationAPI,
  CourseListAPI,
  JobAPI,
} from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import PathwayPersonalize from "./PathwayPersonalize";
import PathwayCombo from "./PathwayCombo";
import AlertComponent from "../../commonComponents/AlertComponent";
import { useScroll } from "../../commonComponents/ScrollContext";
import { useLoginState } from "../../utils/LoginStateContext";
const Pathway = () => {
  const roleURL = AspirationAPI;
  const industryURL = IndustryAPI;
  const qualificationURL = QualificationAPI;
  const jobURL = JobAPI;
  const [aspirationData, setAspirationData] = useState(null);
  const [industryData, setIndustryData] = useState(null);
  const [qualificationData, setQualificationData] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [allSelectionMade, setAllSelectionMade] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const {
    selectedQualification,
    selectedIndustry,
    selectedRole,
    setSelectedRole,
    setSelectedIndustry,
    setSelectedQualification,
  } = useLoginState();

  const [displayBundle, setDisplayBundle] = useState(false);
  const [displayFindButton, setDisplayFindButton] = useState(true);
  const [roleId, setRoleId] = useState(0);
  const [industryId, setIndustryId] = useState(0);
  const [qualificationId, setQualificationId] = useState(0);

  const { pathwayRef } = useScroll();

  const noOptions = [
    {
      id: "no_options_001",
      qualification: "No Options",
      aspirationDesc: "No Options",
      industryName: "No Options",
    },
  ];
  const isLoggedIn = () => {
    const userinfo = sessionStorage.getItem("userInfo");
    return userinfo && userinfo !== "null" && userinfo !== "undefined";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [qualificationResponse, industryResponse, roleResponse] =
          await Promise.all([
            callAPI(qualificationURL, "POST"),
            callAPI(industryURL, "POST"),
            callAPI(roleURL, "POST"),
          ]);
        const { data: qualificationData } = qualificationResponse;
        const { data: industryData } = industryResponse;
        const { data: roleData } = roleResponse;
        setAspirationData(roleData);
        setIndustryData(industryData);
        setQualificationData(qualificationData);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);
  const updateIds = () => {
    setRoleId(selectedRole?.id);
    setIndustryId(selectedIndustry?.id);
    setQualificationId(selectedQualification?.qualification_id);
  };
  useEffect(() => {
    if (!isLoggedIn()) {
      // Reset state and session storage if the user is not logged in
      setSelectedQualification(null);
      setSelectedRole(null);
      setSelectedIndustry(null);
      sessionStorage.removeItem("selectedQualification");
      sessionStorage.removeItem("selectedRole");
      sessionStorage.removeItem("selectedIndustry");
    }
  }, []);
  sessionStorage.setItem("RoleID", roleId);
  sessionStorage.setItem("IndustryID", industryId);
  sessionStorage.setItem("QualificationID", qualificationId);
  const handleQualificationChange = (event, newValue) => {
    setSelectedQualification(newValue);
    sessionStorage.setItem("selectedQualification", JSON.stringify(newValue));
    setDisplayFindButton(true);
    setJobData(null);
    setDisplayBundle(false);
  };
  const handleRoleChange = (event, newValue) => {
    setSelectedRole(newValue);
    sessionStorage.setItem("selectedRole", JSON.stringify(newValue));
    setDisplayFindButton(true);
    setJobData(null);

    setDisplayBundle(false);
  };

  const handleIndustryChange = (event, newValue) => {
    setSelectedIndustry(newValue);
    sessionStorage.setItem("selectedIndustry", JSON.stringify(newValue));
    setDisplayFindButton(true);
    setJobData(null);

    setDisplayBundle(false);
  };

  const checkAllSelection = () => {
    const hasRole = selectedQualification !== null;
    const hasIndustry = selectedIndustry !== null;
    const hasQualification = selectedQualification !== null;
    setAllSelectionMade(hasIndustry && hasQualification && hasRole);
  };

  useEffect(() => {
    checkAllSelection();
    updateIds();
  }, [selectedIndustry, selectedQualification, selectedRole]);

  const handleFindPathway = () => {
    if (!allSelectionMade) {
      setShowAlert(true);
      return;
    }

    setDisplayBundle(true);
    setDisplayFindButton(false);

  };

  useEffect(() => {
    const queryParams = {
      _industry: selectedIndustry?.id,
      _qualification: selectedQualification?.qualification_id,
      _aspiration: selectedRole?.id,
      _courseId: 0,
      _unvId: 0,
      _locationId: 0,
      _jobId: 0,
    };
    const fetchComboData = async () => {
      try {
        const { data } = await callAPI(jobURL, "POST", queryParams, {
          Authorization: "Bearer YourAccessToken",
        });
        // Sort the data
    // const sortedData = data.sort((a, b) => {
    //   if (a.companyLogo && b.companyLogo) return 0;
    //   if (a.companyLogo) return -1;
    //   if (b.companyLogo) return 1;
    //   return 0;
    // });

    setJobData(data);
      } catch (error) {
        console.log(error);
      }
    };
    if (allSelectionMade) {
      fetchComboData();
    }
  }, [selectedIndustry, selectedQualification, selectedRole, allSelectionMade]);
  
  useEffect(() => {
    let pathwayButton = document.querySelector('.pathway-button'); 
    const classnameToAdd = "findpathway";
  
    if (pathwayButton) {
      pathwayButton.children[0].classList.add(classnameToAdd); 
    }
  },[]);

  return (
    <div className="pathway" ref={pathwayRef} id="personalise-home-div">
      <div className="main-pathway">
        <h2>
          Corporate Career, Government Job, Entrepreneurship! <br></br>{" "}
          <span>What's On Your Mind?</span>
        </h2>
        <div className="pathway-select">
          <div className="pathway-select-title">
            <p>I am</p>
            <AutocompleteTextField
              options={qualificationData ? qualificationData : noOptions}
              placeholder="Select Qualifications"
              className={"combobox"}
              TextFieldStyle={"textfield-box"}
              value={selectedQualification}
              onChange={handleQualificationChange}
              getOptionLabel={(option) => option?.qualification}
            />
          </div>
          <div className="pathway-select-title">
            <p>I want to be</p>
            <AutocompleteTextField
              options={aspirationData ? aspirationData : noOptions}
              placeholder="Select Role"
              className={"combobox"}
              TextFieldStyle={"textfield-box"}
              value={selectedRole}
              onChange={handleRoleChange}
              getOptionLabel={(option) => option?.aspirationDesc}
            />
          </div>
          <div className="pathway-select-title">
            <p>In </p>
            <AutocompleteTextField
              options={industryData ? industryData : noOptions}
              placeholder="Select Industry"
              className={"combobox"}
              TextFieldStyle={"textfield-box"}
              value={selectedIndustry}
              onChange={handleIndustryChange}
              getOptionLabel={(option) => option?.industryName}
            />
          </div>
          <div className="pathway-button">
          {displayFindButton && (
            <>
              <Button className={"gradient-button"} onClick={handleFindPathway}>
                Find Pathway
              </Button>
            </>
          )}
        </div>
        </div>
        
      </div>

      {displayBundle && (
        <>
          <PathwayPersonalize />
        </>
      )}

      {displayBundle && jobData !== null && jobData.length !== 0 && (
        <>
          <div className="or-section">
            <div>
              {" "}
              <hr></hr>{" "}
            </div>
            <h2>OR</h2>
            <div>
              {" "}
              <hr></hr>{" "}
            </div>
          </div>
          <PathwayCombo jobData={jobData} />
        </>
      )}

      <AlertComponent
        open={showAlert}
        onClose={() => setShowAlert(false)}
        message="Please select all fields"
        severity="error"
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </div>
  );
};

export default Pathway;
