import React, { useEffect, useState } from "react";
import ButtonComponent from "../../commonComponents/Button";
import { InitiateAssessmentAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import LinearProgressComponent from "../../commonComponents/LinearProgress";
import ModalComponent from "../../commonComponents/ModalComponent";
import Signin from "../CustomizePathway/Signin";
import CloseIcon from "@mui/icons-material/Close";
import { useLoginState } from "../../utils/LoginStateContext";
import { AptStatusAPI } from "../../utils/apiUrl";
import { useLocation, useNavigate, Link } from "react-router-dom";

export const AptitudeTestComponent = ({ testURL, onClose, status }) => {
  const [showProgressBar, setShowProgressBar] = useState(true);
  const handleProgressChange = () => {
    setShowProgressBar(false);
  };

  return (
    <>
      <div style={{ backgroundColor: "white", width: "90vw", height: "100%" }}>
        {showProgressBar ? (
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              maxHeight: "600px",
            }}
          >
            <div className="aptitude-test-progressbar">
              <h1>Aptitude Test</h1>
              <div className="progressbar-container">
                <h3>
                  Thank you for showing interest. <br></br> Please wait while we
                  redirect you to the test site.
                </h3>
                <LinearProgressComponent
                  onProgressChange={handleProgressChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <ButtonComponent
              style={{ display: "flex", justifyContent: "end", float: "right" }}
              onClick={onClose}
            >
              <CloseIcon color="white" />
            </ButtonComponent>
            <iframe src={testURL} height="650px" width="100%"></iframe>
          </>
        )}
      </div>
    </>
  );
};

const AptiTest = () => {
  const { isLoggedIn } = useLoginState();
  const navigate = useNavigate();
  const location = useLocation();
  const InitiateAssessmentURL = InitiateAssessmentAPI;
  const AptStatusURL = AptStatusAPI;
  const [aptiData, setAptiData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);
  const queryParams = {
    mobileNo: sessionStorage.getItem("mobileNo"),
  };

  const fetchAptiData = async () => {
    try {
      const { data } = await callAPI(
        InitiateAssessmentURL,
        "POST",
        queryParams,
        {
          Authorization: "Bearer YourAccessToken",
        }
      );
      setAptiData(data);
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const _mobileNo = sessionStorage.getItem("mobileNo");
  const [testStatus, setTestStatus] = useState("");
  const fetchData = async () => {
    try {
      const [Response] = await Promise.all([
        callAPI(AptStatusURL, "POST", {
          _mobileNo: _mobileNo,
        }),
      ]);
      const { data } = Response;
      // console.log(data[0].status);
      if (data && data.length > 0 && data[0].status) {
        setTestStatus(data[0].status);
        if (testStatus && testStatus === "Completed") {
          navigate("/aptitude/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (testStatus && testStatus === "Completed") {
      navigate("/aptitude/");
    }
  }, [testStatus]);
  // fetchData();

  return (
    <div className='apti-test home_aptitudetest_button'>
      <h1>Not Sure About Your Career Path?</h1> 

      {isLoggedIn === true ? (
        <Link to="/aptitude/give-test/" onClick={handleClose}  className="apti-button" style={{
    padding: `16px`,
    fontSize: `18px`,
    color: 'black',
    fontWeight: '700',
    textAlign: 'center'
}}>
                      <p style={{ fontWeight: '600',}}>
                        Take the Aptitude Test
                      </p>
                    </Link>
      ) : (
        <ModalComponent
          buttonContent={"Take the Aptitude Test"}
          buttonClassName={"apti-button"}
          style={{ backgroundColour: "white" }}
          children={
            <Signin sourceComponent={"aptitude-test"} onClose={handleClose} />
          }
          open={isModalOpen}
          setOpen={setIsModalOpen}
          onOpen={handleOpen}
          onClose={handleClose}
        />
      )}
    </div>
  );
};
export default AptiTest;
