import React from 'react'
import ButtonComponent from '../../commonComponents/Button'
import { Link, useLocation } from 'react-router-dom'
import CoursesOffered from './CoursesOffered'
import CareerBanner from './CareerBanner'
import { useIds } from '../../utils/IdsContext.js';
const AboutUniversity = () => {
  const { universityId, setUniversityId } = useIds();
  const location = useLocation();
  const isUniversityPage = location.pathname.startsWith('/eco-system/university-partnership/');
  console.log(isUniversityPage);
  const isPathwayComparisonPage = location.pathname.startsWith('/pathway-comparison/');
  return (
    <>
    {isPathwayComparisonPage ? (
      <div className='about-university-button-section'>
      <Link to="/pathway-comparison/"><ButtonComponent children={'Back'} className={'back-button'}/></Link>
  </div>
    ): isUniversityPage ? (
      <>
        <CoursesOffered />
        <CareerBanner />
      </>
    ): null}
    </>
  )
}
export default AboutUniversity