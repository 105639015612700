import React, { useState, useEffect, useRef } from "react";
import Logo from "../../assets/digavarsity_logo.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ButtonComponent from "../../commonComponents/Button";
import { useScroll } from "../../commonComponents/ScrollContext";
import HbCrsImage1 from "../../assets/hb_crs_img1.webp";
import HbCrsImage2 from "../../assets/hb_crs_img2.webp";
import HbCrsImage3 from "../../assets/hb_crs_img3.webp";
import HbCrsImage4 from "../../assets/hb_crs_img4.webp";
import HbCrsImage5 from "../../assets/hb_crs_img5.webp";
import HbCrsImage6 from "../../assets/hb_crs_img6.webp";
import HbCrsImage7 from "../../assets/hb_crs_img7.webp";
import HbCrsImage8 from "../../assets/hb_crs_img8.webp";
import HbCrsImage9 from "../../assets/hb_crs_img9.webp";
import HbCrsImage10 from "../../assets/hb_crs_img10.webp";
import HbCrsImageLng1 from "../../assets/HbCrsLngImage1.webp";
import HbCrsImageLng2 from "../../assets/HbCrsLngImage2.webp";
import HbCrsImageLng3 from "../../assets/HbCrsLngImage3.webp";
import HbCrsImageLng4 from "../../assets/HbCrsLngImage4.webp";
import HbCrsImageLng5 from "../../assets/HbCrsLngImage5.webp";
import HbCrsImageLng6 from "../../assets/HbCrsLngImage6.webp";
import HbCrsImageLng7 from "../../assets/HbCrsLngImage7.webp";
import HbCrsImageLng8 from "../../assets/HbCrsLngImage8.webp";
import HbCrsImageLng9 from "../../assets/HbCrsLngImage9.webp";
import HbCrsImageLng10 from "../../assets/HbCrsLngImage10.webp";
import { AssetsAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import { Helmet } from "react-helmet-async";

// Preload images
const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};
const carousel1 = [
  //left
  { id: "001", img_src: HbCrsImage1 },
  { id: "002", img_src: HbCrsImage2 },
  { id: "003", img_src: HbCrsImage3 },
  { id: "004", img_src: HbCrsImage4 },
  { id: "005", img_src: HbCrsImage5 },
  { id: "006", img_src: HbCrsImage6 },
  { id: "007", img_src: HbCrsImage7 },
  { id: "008", img_src: HbCrsImage8 },
  { id: "009", img_src: HbCrsImage9 },
  { id: "010", img_src: HbCrsImage10 },
];

const carousel2 = [
  //right
  { id: "001", img_src: HbCrsImage6 },
  { id: "002", img_src: HbCrsImage7 },
  { id: "003", img_src: HbCrsImage8 },
  { id: "004", img_src: HbCrsImage9 },
  { id: "005", img_src: HbCrsImage10 },
  { id: "006", img_src: HbCrsImage1 },
  { id: "007", img_src: HbCrsImage2 },
  { id: "008", img_src: HbCrsImage3 },
  { id: "009", img_src: HbCrsImage4 },
  { id: "010", img_src: HbCrsImage5 },
];
const carousel3 = [
  //left-after flip
  { id: "001", img_src: HbCrsImageLng1 },
  { id: "002", img_src: HbCrsImageLng2 },
  { id: "003", img_src: HbCrsImageLng3 },
  { id: "004", img_src: HbCrsImageLng4 },
  { id: "005", img_src: HbCrsImageLng5 },
  { id: "006", img_src: HbCrsImageLng6 },
  { id: "007", img_src: HbCrsImageLng7 },
  { id: "008", img_src: HbCrsImageLng8 },
  { id: "009", img_src: HbCrsImageLng9 },
  { id: "010", img_src: HbCrsImageLng10 },
];
const carousel4 = [
  //right-after flip
  { id: "001", img_src: HbCrsImageLng6 },
  { id: "002", img_src: HbCrsImageLng7 },
  { id: "003", img_src: HbCrsImageLng8 },
  { id: "004", img_src: HbCrsImageLng9 },
  { id: "005", img_src: HbCrsImageLng10 },
  { id: "006", img_src: HbCrsImageLng1 },
  { id: "007", img_src: HbCrsImageLng2 },
  { id: "008", img_src: HbCrsImageLng3 },
  { id: "009", img_src: HbCrsImageLng4 },
  { id: "000", img_src: HbCrsImageLng5 },
];

const HomeBanner = () => {
  const [carousel11, setCarousel11] = useState([]);
  const [carousel21, setCarousel21] = useState([]);
  const [carousel31, setCarousel31] = useState([]);
  const [carousel41, setCarousel41] = useState([]);

  const [currentCarouselLeft, setCurrentCarouselLeft] = useState(carousel1);
  const [currentCarouselRight, setCurrentCarouselRight] = useState(carousel2);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const swiperRef = useRef(null);

  const { scrollToChoosePathway } = useScroll();

  useEffect(() => {
    const fetchAssetData = async () => {
      try {
        const { data } = await callAPI(
          AssetsAPI,
          "POST",
          { pageName: "Home" },
          {
            Authorization: "Bearer YourAccessToken",
          }
        );
        setResponse(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchAssetData();
  }, []);

  useEffect(() => {
    if (response && response.length > 0) {
      const bannerData = response[0].sections[0];
      if (
        bannerData &&
        bannerData.subSections &&
        bannerData.subSections.length > 0
      ) {
        const mapAssetsToCarousel = (subSections) => {
          return subSections.map((subSection) => {
            return subSection.sectionAssets.map((asset) => ({
              id: asset.assetId,
              img_src: asset.imageUrl,
            }));
          });
        };

        const [carousel1, carousel2, carousel3, carousel4] =
          mapAssetsToCarousel(bannerData.subSections);
        setCarousel11(carousel1 || []);
        setCarousel21(carousel2 || []);
        setCarousel31(carousel3 || []);
        setCarousel41(carousel4 || []);

        // Preload images
        const allImages = [
          ...carousel1.map((item) => item.img_src),
          ...carousel2.map((item) => item.img_src),
          ...carousel3.map((item) => item.img_src),
          ...carousel4.map((item) => item.img_src),
        ];
        preloadImages(allImages);

        // Set data loaded to true after setting carousels
        setDataLoaded(true);
      }
    }
  }, [response]);

  useEffect(() => {
    if (dataLoaded) {
      let animationTimerId;
      const flipAnimationInterval = setInterval(() => {
        applyFlipAnimation();
        animationTimerId = setTimeout(() => {
          setCurrentCarouselLeft((prevCarousel) =>
            prevCarousel === carousel11 ? carousel31 : carousel11
          );
          setCurrentCarouselRight((prevCarousel) =>
            prevCarousel === carousel21 ? carousel41 : carousel21
          );
        }, 9000);
      }, 9000);

      return () => {
        clearInterval(flipAnimationInterval);
        clearTimeout(animationTimerId);
      };
    }
  }, [dataLoaded, carousel11, carousel21, carousel31, carousel41]);

  const applyFlipAnimation = () => {
    const slides = document.querySelectorAll(".swiper-slide-homebanner");
    slides.forEach((slide) => {
      const isInViewport =
        slide.getBoundingClientRect().top < window.innerHeight &&
        slide.getBoundingClientRect().bottom >= 0;
      if (isInViewport) {
        slide.classList.add("flip-animation");
      } else {
        slide.classList.remove("flip-animation");
      }
    });
  };

  if (loading) {
    return (
      <div className="home-banner">
        <div className="left-section">
          <img src={Logo} alt="logo" className="logo" />
          <div className="title">
            <h1>
              The <br /> University <br /> Of <span>WORK</span> <sup>™</sup>{" "}
            </h1>
            <ButtonComponent
              className={"motto"}
              onClick={scrollToChoosePathway}
            >
              <p>
                Your Pathway To A <span> Work Integrated Career </span> Begins
                Here
              </p>
              <KeyboardDoubleArrowDownIcon />
            </ButtonComponent>
          </div>
        </div>
        <div className="right-section">
          <div className="loading-placeholder">Loading...</div>
        </div>
      </div>
    );
  }

  if (!response || response.length === 0) {
    return (
      <div className="home-banner">
        <div className="left-section">
          <img src={Logo} alt="logo" className="logo" />
          <div className="title">
            <h1>
              The <br /> University <br /> Of <span>WORK</span> <sup>™</sup>{" "}
            </h1>
            <ButtonComponent
              className={"motto"}
              onClick={scrollToChoosePathway}
            >
              <p>
                Your Pathway To A <span> Work Integrated Career </span> Begins
                Here
              </p>
              <KeyboardDoubleArrowDownIcon />
            </ButtonComponent>
          </div>
        </div>
        <div className="right-section">
          <div className="no-data-message">
            No data available at the moment.
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Digivarsity - Work Integrated Career | Career Counselling & Planning
        </title>
        <meta
          property="og:title"
          content="Digivarsity - Work Integrated Career | Career Guidance & Planning"
        />
        <meta property="og:site_name" content="Digivarsity" />
        <meta property="og:url" content="https://www.digivarsity.com/" />
        <meta
          property="og:description"
          content="Explore Digivarsity, the University of Work. Discover career-oriented courses merging knowledge with real experience, plus expert career counseling to plan your path."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.digivarsity.com/static/media/work_goal_video.b2b8806d4dac1ce1e339.png"
        />
        <meta property="og:image:width" content="6720" />
        <meta property="og:image:height" content="4480" />

        <meta
          name="Description"
          content="Explore Digivarsity, the University of Work. Discover career-oriented courses offering real work experience, expert career counseling, and aptitude tests to plan your path."
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "EducationalOrganization",
            name: "Digivarsity",
            url: "https://www.digivarsity.com/",
            logo: "https://www.digivarsity.com/static/media/digavarsity_logo.61a5abe22e6a621c17e7.png",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+91 7204677888",
              contactType: "customer service",
              areaServed: "IN",
              availableLanguage: ["en", "Hindi"],
            },
            sameAs: [
              "https://www.facebook.com/digivarsity.the.uni.of.work",
              "https://www.instagram.com/digivarsity.the.uni.of.work/",
              "https://www.youtube.com/channel/UCJnL4J428DyhA-KzdNvE5Ng",
              "https://in.linkedin.com/company/digivarsity-the-university-of-work",
            ],
          })}
        </script>
      </Helmet>
      <div className="home-banner">
        <div className="left-section">
          <img src={Logo} alt="logo" className="logo" />
          <div className="title">
            <h1>
              The <br></br>University <br></br>Of <span>WORK</span> <sup>™</sup>{" "}
            </h1>
            <ButtonComponent
              className={"motto"}
              onClick={scrollToChoosePathway}
            >
              <p>
                Your Pathway To A <span> Work Integrated Career </span> Begins
                Here
              </p>
              <KeyboardDoubleArrowDownIcon />
            </ButtonComponent>
          </div>
        </div>

        <div className="right-section">
          <div className="right-section-left">
            <Swiper
              ref={swiperRef}
              slidesPerView={3}
              breakpoints={{
                360: {
                  slidesPerView: 6.5,
                },
                395: {
                  slidesPerView: 6.1,
                },
                640: {
                  slidesPerView: 4,
                },
                768: {
                  slidesPerView: 5.5,
                  // spaceBetween: 25
                },
                1024: {
                  slidesPerView: 4,
                },
                1080: {
                  slidesPerView: 3.6,
                },
                1180: {
                  slidesPerView: 3.4,
                },

                1366: {
                  slidesPerView: 3.1,
                },
                1440: {
                  slidesPerView: 3,
                },
                1540: {
                  slidesPerView: 2.8,
                },
              }}
              touchRatio={0}
              allowTouchMove={false}
              direction="vertical"
              height={880}
              speed={5000}
              loop={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
                reverseDirection: false,
              }}
              modules={[Autoplay]}
              className="mySwiper fromEdge"
            >
              {currentCarouselLeft.map((item) => {
                return (
                  <SwiperSlide
                    className={`swiper-slide-homebanner`}
                    key={item.id}
                  >
                    <img src={item.img_src} loading="lazy" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="right-section-right">
            <Swiper
              ref={swiperRef}
              slidesPerView={3}
              breakpoints={{
                360: {
                  slidesPerView: 6.5,
                },
                395: {
                  slidesPerView: 6.1,
                },
                640: {
                  slidesPerView: 4,
                },
                768: {
                  slidesPerView: 5.5,
                },
                1024: {
                  slidesPerView: 5,
                },
                1080: {
                  slidesPerView: 4.7,
                },
                1180: {
                  slidesPerView: 4.2,
                },
                1366: {
                  slidesPerView: 3.5,
                },
                1440: {
                  slidesPerView: 3,
                },
                1540: {
                  slidesPerView: 2.6,
                },
              }}
              touchRatio={0}
              allowTouchMove={false}
              direction="vertical"
              effect="slide"
              height={880}
              speed={5000}
              loop={true}
              autoplay={{
                delay: 5,
                disableOnInteraction: false,
                reverseDirection: false,
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {currentCarouselRight.map((item, index) => {
                return (
                  <>
                    <SwiperSlide
                      className="swiper-slide-homebanner"
                      key={item.id}
                    >
                      <img src={item.img_src} lazy="loading" />
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
