import React, { useState } from 'react'
import Dummy from "../../assets/news_image.png"
import news1_image from "../../assets/news1_image.png";
import news2_image from "../../assets/news2_image.png";
import news3_image from "../../assets/news3_image.png";
import news4_image from "../../assets/news4_image.png";
import news5_image from "../../assets/news5_image.png";
import news6_image from "../../assets/news6_image.png";
import news7_image from "../../assets/news7_image.png";
import news1_banner from "../../assets/news1_banner_image.png";
import news2_banner from "../../assets/news2_banner_image.png";
import news3_banner from "../../assets/news3_banner_image.png";
import news4_banner from "../../assets/news4_banner_image.png";
import news5_banner from "../../assets/news5_banner_image.png";
import news6_banner from "../../assets/news6_banner_image.png";
import news7_banner from "../../assets/news7_banner_image.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from "../../commonComponents/Button"
import { Link } from 'react-router-dom';

export const news = [
    {
        id: "107723593",
        route: "shaping-futures-through-strategic-partnerships",
        publisher: "Economic Times",
        date: "15 Feb 2024",
        title: "Digivarsity: Shaping Futures Through Strategic Partnerships in Skill Development.",
        content: "TeamLease EdTech and the National Skill Development Corporation (NSDC) have entered a partnership designed to foster employability through skill development by endorsing work-integrated degree programmes.",
        image_src: (news1_image),
        banner_img_src: (news1_banner),
        news_link: "https://www.google.com/amp/s/m.economictimes.com/jobs/fresher/teamlease-edtech-nsdc-enter-partnership-to-foster-employability-through-skill-development/amp_articleshow/107723593.cms"
    },
    {
        id: "3396728",
        route: "pioneering-transformation-in-education",
        publisher: "Financial Express",
        date: "16 Feb 2024",
        title: "Digivarsity: Pioneering Transformation in Education. Ministry Of Education inaugurates partnership between TeamLease EdTech and NSDC.",
        content: "Dharmendra Pradhan, Minister of Education and Minister of Skill Development and Entrepreneurship, officially inaugurated a partnership between TeamLease EdTech and the National Skill Development Corporation (NSDC).",
        image_src: (news2_image),
        banner_img_src: (news2_banner),
        news_link: "https://www.google.com/amp/s/www.financialexpress.com/jobs-career/education-ministry-of-education-forms-a-partnership-between-teamlease-edtech-and-nsdc-3396728/lite/"
    },
    {
        id: "cxotoday_003",
        route: "digivarsity-teams-up-with-csc-academy",
        publisher: "CXO Today",
        date: "09 Dec 2023",
        title: "Unlocking Jharkhand's Potential: Digivarsity Teams Up with CSC Academy for Enhanced Employability.",
        content: "This partnership cements the educational opportunities for students in Jharkhand to pursue their education with the option to pursue work-integrated programs.",
        image_src: (news3_image),
        banner_img_src: (news3_banner),
        news_link: "https://cxotoday.com/press-release/csc-academy-and-teamlease-edtechs-digivarsity-join-hands-with-the-govt-of-jharkhand-on-a-joint-mission-towards-making-jharkhand-employable/"
    },
    {
        id: "123082900496_1",
        route: "digivarsity-partners-with-pmaps",
        publisher: "Business Standard",
        date: "29 Aug 2023",
        title: "Empowering Talent: Digivarsity Partners with PMAPS to Enhance Skill Development.",
        content: "New Delhi [India], August 29: PMaps, a leading psychometric assessment company, has announced a strategic partnership with Digivarsity by TeamLease EdTech - India's leading learning and employability solutions provider.",
        image_src: (news4_image),
        banner_img_src: (news4_banner),
        news_link: "https://www.google.com/amp/s/www.business-standard.com/amp/content/press-releases-ani/pmaps-collaborates-with-digivarsity-by-teamlease-edtech-to-upskill-talent-123082900496_1.html"
    },
    {
        id: "17157601",
        route: "digivarsity-and-teamlease-edtech-introudce-ai-powered-career-discovery-platform",
        publisher: "CNBC TV 18",
        date: "07 Jul 2023",
        title: "Transforming Careers: Digivarsity and TeamLease EdTech Introduce AI-Powered Career Discovery Platform.",
        content: "According to the company, Digivarsity is India's first AI-powered work-integrated degree and career discovery platform and aims to “revolutionise” higher education in India and empower students with workplace skills along with academic knowledge necessary for long-term career success.",
        image_src: (news5_image),
        banner_img_src: (news5_banner),
        news_link: "https://www.google.com/amp/s/www.cnbctv18.com/startup/teamlease-edtech-digivarsity-ai-powered-career-discovery-platform-17157601.htm/amp"
    },
    {
        id: "101548489",
        route: "teamlease-edtech-introduces-digivarsity",
        publisher: "Economic Times",
        date: "06 Jul 2023",
        title: "Unlocking Career Potential: TeamLease EdTech Introduces Digivarsity, an AI-Powered Platform for Work-Integrated Degrees and Career Discovery.",
        content: "Digivarsity has already partnered with ten universities in India and over 150 employers to offer innovative work-linked degree programs. It enables students to make informed choices about their education and gain practical work experience alongside their college studies.",
        image_src: (news6_image),
        banner_img_src: (news6_banner),
        news_link: "https://www.google.com/amp/s/m.economictimes.com/tech/technology/teamlease-edtech-launches-ai-powered-work-integrated-degree-and-career-discovery-platform-digivarsity/amp_articleshow/101548489.cms"
    },
    {
        id: "100800998",
        route: "teamlease-edtech-and-skills-india-partner-for-digivarsity",
        publisher: "Economic Times",
        date: "06 Jun 2023",
        title: "Empowering Skill Development: TeamLease EdTech and Skill India Partner for Digivarsity Initiative.",
        content: "The segment, called ‘Digivarsity,’ will have potential employers train students while they are still in college to increase their employability.",
        image_src: (news7_image),
        banner_img_src: (news7_banner),
        news_link: "https://www.google.com/amp/s/m.economictimes.com/tech/startups/teamlease-edtech-to-partner-with-skill-india-for-digivarsity/amp_articleshow/100800998.cms"
    },
];

export const NewsCard = ({ id, image_src, publisher, date, title, content, news_link, className }) => {
    return (
        <>
            <div className={className} id={id}>
                <img src={image_src} alt='news' className='new-image-size' />
                <div className='news-content'>
                    <h6>{publisher} - {date}</h6>
                    <h3>{title}</h3>
                    <p>{content}</p>
                    <Link to={news_link} target='_blank' rel="noopener noreferrer">
                    <h5>
                        Read More
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <mask id="mask0_2918_20272" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                <rect width="16" height="16" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_2918_20272)">
                                <path d="M11.0744 8.49924H3.5C3.35812 8.49924 3.23932 8.45138 3.1436 8.35565C3.04787 8.25993 3 8.14113 3 7.99925C3 7.85738 3.04787 7.73858 3.1436 7.64285C3.23932 7.54713 3.35812 7.49927 3.5 7.49927H11.0744L8.87947 5.30437C8.78032 5.20523 8.73139 5.0892 8.73268 4.9563C8.73396 4.8234 8.78289 4.70524 8.87947 4.60182C8.98289 4.49841 9.10169 4.44499 9.23587 4.44157C9.37006 4.43815 9.48886 4.48815 9.59227 4.59157L12.5782 7.57747C12.6406 7.63986 12.6846 7.70567 12.7102 7.7749C12.7359 7.84413 12.7487 7.91891 12.7487 7.99925C12.7487 8.0796 12.7359 8.15438 12.7102 8.2236C12.6846 8.29284 12.6406 8.35865 12.5782 8.42104L9.59227 11.4069C9.49313 11.5061 9.37541 11.555 9.23908 11.5537C9.10276 11.5524 8.98289 11.5001 8.87947 11.3967C8.78289 11.2933 8.73289 11.1762 8.72947 11.0454C8.72606 10.9146 8.77606 10.7975 8.87947 10.6941L11.0744 8.49924Z" fill="#2C5FF8" />
                            </g>
                        </svg>

                    </h5>
                    </Link>
                </div>
            </div>

        </>
    )
}

const News = () => {
    const displayedNews = news.slice(0, 2);
    const [eventsExist, setEventExist] = useState(false);
    return (
        <div className='news'>
            <h2 className='heading'>Digiverse</h2>
            <div className='news-container'>
                <div className='left-section'>

                    {displayedNews.map((item, index) => {
                        return (
                            <NewsCard

                                image_src={item.image_src}
                                publisher={item.publisher}
                                date={item.date}
                                title={item.title}
                                content={item.content}
                                news_link={item.news_link}
                                className={'news-card'}
                            />
                        )
                    })}
                    <div className='view-more-button'>
                        <Link to="/digiverse/"><Button className={'gradient-border-button'}>View More</Button></Link>
                    </div>

                </div>
                <div className='right-section'>
                    {eventsExist===true? (
                        <>
                            <Link style={{ textDecoration: "none", color: "white" }} to="/events">
                                <div className='icon-text-inline'>
                                    <h5>View More Events</h5>
                                    <ArrowForwardIcon />
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "white" }} to="/events">
                            <div className='div_imgbg'>
                                <div className='black-cover'></div>
                                <div className='top-right'>
                                    <div>
                                        <h2>25<sup>th</sup></h2>
                                        <h4>March </h4>
                                        <h5>10 AM - 6 PM</h5>
                                    </div>
                                </div>

                                <div className='bottom-left'>
                                    <Link to="/events"><Button className={'bottom-left-button'}>Upcoming Event</Button></Link>
                                    <p>On Job Training (OJT) Mela - <br></br> <h2>Ranchi</h2></p>
                                </div>
                            </div>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link style={{ textDecoration: "none", color: "white" }} to="/reports/future-of-apprenticeship">
                                <div className='icon-text-inline'>
                                    <h5>View More</h5>
                                    <ArrowForwardIcon />
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "white" }} to="/reports/future-of-apprenticeship">
                            <div className='div_imgbg'> 
                                <div className='black-cover'></div>
                                <div className='top-right'>
                                    <div>
                                        <h1>25<sup>th</sup></h1>
                                        <h4>March </h4>
                                    </div>
                                </div>

                                <div className='bottom-left'>
                                    <Link to="/reports/future-of-apprenticeship"><Button className={'bottom-left-button'}>Reports 2023-2024</Button></Link>
                                    <p>How To Fix The Problems In India's Higher Education System</p>
                                </div>
                            </div>
                            </Link>
                        </>
                    )}

                </div>

            </div>

        </div>
    )
}

export default News

