import React from "react";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import { Helmet } from "react-helmet-async";

const paths = [
  { label: "Home", url: "/" },
  { label: "Digiverse", url: "/digiverse/" },
  { label: "Events", url: "/events/" },
];

const EventPath = () => {
  return (
    <>
      <Helmet>
        <title>
          Online Webinars for Students - Education & Career Counseling
        </title>
        <meta name="keywords" content="
  online free webinars, 
  webinars for students, 
  career counselling webinars, 
  online webinars for students, 
  free webinars for students, 
  upcoming webinars for students, 
  live webinars for students, 
  online education webinars, 
  free professional development webinars, 
  free educational webinars with certificates, 
  free educational webinars, 
  virtual events for students
" />

        <meta
          name="Description"
          content="Join our online free webinars for students and boost your career with expert-led sessions. Discover upcoming webinars, gain insightful actionable and professional development."
        ></meta><link rel="canonical" href={window.location.href}/>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5075643,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.digivarsity.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Digiverse",
                item: "https://www.digivarsity.com/digiverse/",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: "Events",
                item: "https://www.digivarsity.com/events/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="event-path">
        <BreadCrumbs paths={paths} color="black" />
        <h1>Events</h1>
      </div>
    </>
  );
};

export default EventPath;
