import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import BannerBackground from "../../assets/CourseDetails-Banner.png";
import UniLogo1 from "../../assets/Univ-logo1.png";
import UniLogo2 from "../../assets/Univ-logo2-1.png";
import UniLogo3 from "../../assets/Univ-logo3.png";
import UniLogo4 from "../../assets/Univ-logo4.png";
import UniLogo5 from "../../assets/Univ-logo5.png";
import ButtonComponent from "../../commonComponents/Button";
import BreadCrumbs from "../../commonComponents/BreadCrumbs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { CoureDetailsAPI } from "../../utils/apiUrl";
import callAPI from "../../utils/apiAction";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import PopoverComponent from "../../commonComponents/Popover";
import ShareComponent from "../../components/ShareComponent";
import { useIds } from "../../utils/IdsContext";
import DummyJob from "../../assets/DummyJob.png";

const DescriptionWithReadMore = ({ text, maxWords }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const words = text.split(" ");
  const shortText = words.slice(0, maxWords).join(" ");
  const isTruncated = words.length > maxWords;
  return (
    <div>
      <p>
        {isExpanded || !isTruncated ? text : `${shortText}...`}
        {isTruncated && (
          <button className="readmore" onClick={toggleReadMore}>
            {isExpanded ? "...read less" : "read more"}
          </button>
        )}
      </p>
    </div>
  );
};

const CoursePath = () => {
  const navigate = useNavigate();
  const CourseDetailsURL = CoureDetailsAPI;
  const location = useLocation();
  const { uniName, feeProps } = location.state || {};
  const { img } = location.state || {};
  const {unicourName, courseName}= useParams();
  console.log(unicourName);
  const { universityId, setUniversityId, courseId, universityMap, courseMap, setCourseId } = useIds();
  const univId = universityId;
  const shouldShowIntOpp = () => {
    const pathname = location.pathname;
    return (
      !pathname.includes("/pathway-comparison/") &&
      pathname.includes("/eco-system/")
    );
  };
  const [courseDetailsData, setCourseDetailsData] = useState([]);
  const [courseData, setCourseData] = useState();
  const [feeData, setFeeData] = useState();
  const [jobData, setJobData] = useState();

      const fetchCourseData = async (cid,uid) => {
        try {
          const [CourseDetailsResponse] = await Promise.all([
            callAPI(CourseDetailsURL, "POST", {
              courseId: cid,
              univId: uid,
            }),
          ]);
          const { data } = CourseDetailsResponse;
          setCourseDetailsData(data);

          if (data && data[0] && data[0].courses) {
            setCourseData(data[0].courses[0]);
            setCourseData(data[0].courses[0]);
            setFeeData(data[0].feesDetails);
            setJobData(data[0].jobList);
          } else {
            console.log("No courses found in the response");
          }
        } catch (e) {
          console.log(e);
        }
      };

  useEffect(() => {
    console.log("in effect");
    if (Object.keys(universityMap).length > 0) {
      const id = Object.keys(universityMap).find(
        (key) => universityMap[key] === unicourName
      );
      console.log(id);
      if(id){
        setUniversityId(id);
        localStorage.setItem("universityId", universityId);
      }
    }
  }, [unicourName, universityId, setUniversityId, universityMap]);

  useEffect(() => {
    console.log("in effect");
    console.log(courseMap);
    console.log(courseName)
    if (Object.keys(courseMap).length > 0) {
      const id = Object.keys(courseMap).find(
        (key) => courseMap[key] === courseName
      );
      console.log(id);
      if(id){
        setCourseId(id);
        localStorage.setItem("courseId", courseId);
      }
    }
  }, [courseName, courseId, setCourseId, courseMap]);

  useEffect(()=>{
    if(courseId && universityId){
      fetchCourseData(courseId, universityId);
    }
  },[courseId && universityId])




  const paths = [
    { label: "Home", url: "/" },
    // { label: "Personalise Pathway", url: "/personalize-pathway/" },
    { label: "Pathway Comparison", url: "/pathway-comparison/" },
    { label: `${courseData && courseData.courseName}`,url: 
       `/pathway-comparison/${unicourName}/${courseName}/`,
  }
  ];
  if (shouldShowIntOpp()) {
    paths[1] = { label: "Ecosystem", url: "/eco-system/" };
    paths[2] = {
      label: `${unicourName}`,
      url: `/eco-system/${unicourName}/`,
      customOnClick: () => navigate(-1),
    };
    paths[3] = {
      label: `${courseData && courseData.courseName}`,url: 
       `/eco-system/${unicourName}/${courseName}/`
    };
  }

  const [anchorElShare, setAnchorElShare] = useState(null);
  const closePopover = (setter) => {
    setter(null);
  };
  const handleClickShare = (event) => {
    setAnchorElShare(event.currentTarget);
    handleFinalShare();
  };
  const [shareImageUrl, setShareImageUrl] = useState("");
  const handleFinalShare = async () => {
    // Check if the elements exist before proceeding
    const summaryPath = document.querySelector(".summary-path");
    if (!summaryPath) {
      console.error("Summary path element not found");
      return;
    }

    const topSection = document.querySelector(".top-section");
    if (!topSection) {
      console.error("Top section element not found");
      return;
    }

    const button = document.querySelector(".secondary-share-btn");
    if (!button) {
      console.error("Share button not found");
      return;
    }

    // Hide the top section
    topSection.style.display = "none";

    // Proceed with generating the canvas
    html2canvas(summaryPath)
      .then((canvas) => {
        // Show the top section again
        topSection.style.display = "";

        // Get the image data
        const imgData = canvas.toDataURL("image/png");
        setShareImageUrl(imgData); // Directly setting the share image URL
      })
      .catch((err) => {
        // Show the top section again in case of error
        topSection.style.display = "";
        console.error("Error generating share content: ", err);
      });
  };

  return (
    <>
      <div className="summary-path" id="couseHeader">
        <div className="top-section" id="course-details-breadcrumbs-section">
          <ButtonComponent
            className={"back-button"}
            onClick={() => navigate(-1)}
          >
            {" "}
            <KeyboardBackspaceIcon /> Back{" "}
          </ButtonComponent>
          <BreadCrumbs paths={paths} color="white" />
        </div>
        <div className="article-box">
          <h2>{courseData && courseData.courseName}</h2>
          <p>
            {courseData && (
              <DescriptionWithReadMore
                text={courseData.longDesc}
                maxWords={35}
              />
            )}
          </p>
          <div className="icons-article-box">
            <div className="icon-article-box border-right">
              <img
                src={
                  jobData && jobData.length > 0
                    ? jobData[0].universityLogo
                    : img
                }
                alt="UniLogo"
              />
            </div>

            {uniName !== "Aligarh Muslim University" ? (
              ""
            ) : (
              <>
                <div className="icon-article-box border-right">
                  <img src={UniLogo2} alt="UniLogo" />
                </div>
                <div className="icon-article-box border-right">
                  <img src={UniLogo3} alt="UniLogo" />
                </div>
                <div className="icon-article-box border-right">
                  <img src={UniLogo4} alt="UniLogo" />
                </div>
                <div className="icon-article-box">
                  <img src={UniLogo5} alt="UniLogo" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CoursePath;
